import { CustomError } from 'timeone-components';
import {
  getPublishersApi,
  getPendingPublishersApi,
  getCampaignGroupsCommissionApi,
  postProviderSubscriptionsApi,
  getDetailPublisherApi,
  getPublishersDetailsApi,
  getRefusedReasonsApi,
  getDistributionMethodsApi,
} from './publisher.api';
import { handleFetchResponse } from '../utils';

export const getPublishers = ({ campaignId }) =>
  getPublishersApi({ campaignId }).then(({ data }) => {
    if (data?.collectionQuerySubscribedAndRefuseds) {
      return data.collectionQuerySubscribedAndRefuseds;
    }

    throw new Error('Error while fetching publishers');
  });

export const getPendingPublishers = ({ campaignId }) =>
  getPendingPublishersApi({ campaignId }).then(({ data }) => {
    if (data?.collectionQueryPendings) {
      return data.collectionQueryPendings;
    }

    throw new Error('Error while fetching publishers');
  });

export const getDetailPublisher = ({ campaignId, providerId }) =>
  getDetailPublisherApi({ campaignId, providerId }).then(({ data }) => {
    if (data?.itemQueryProviderDetailsForCampaign) {
      if (data.itemQueryProviderDetailsForCampaign.provider?.site) {
        return data.itemQueryProviderDetailsForCampaign;
      }

      throw new CustomError({ type: 404 });
    }

    throw new Error('Error while fetching publisher details');
  });

export const getPublishersDetails = ({ idList }) =>
  getPublishersDetailsApi({ idList }).then(({ data }) => {
    if (data?.collectionQueryProviders) {
      if (data.collectionQueryProviders) {
        return data.collectionQueryProviders;
      }

      throw new CustomError({ type: 404 });
    }

    throw new Error('Error while fetching publisher details');
  });

export const getCampaignGroupsCommission = async payload => {
  const response = await getCampaignGroupsCommissionApi(payload);

  return handleFetchResponse(response);
};

export const getDistributionMethods = async options => {
  const response = await getDistributionMethodsApi(options);

  return handleFetchResponse(response);
};

export const getRefusedReasons = async payload => {
  const response = await getRefusedReasonsApi(payload);

  return handleFetchResponse(response);
};

export const acceptProviders = async ({ advertiserId, campaignId, providerIds, commissionGroupId }) => {
  const body = providerIds.map(id => ({
    serviceProviderId: id,
    groupOfCommissionId: commissionGroupId,
    status: 'active',
  }));

  const response = await postProviderSubscriptionsApi({ advertiserId, campaignId, body });

  return handleFetchResponse(response);
};

export const declineProviders = async ({ advertiserId, campaignId, providerIds, reason }) => {
  const body = providerIds.map(id => ({
    serviceProviderId: id,
    status: 'refused',
    extra: reason,
  }));

  const response = await postProviderSubscriptionsApi({ advertiserId, campaignId, body });

  return handleFetchResponse(response);
};

export default {
  getPublishers,
  getPendingPublishers,
  getCampaignGroupsCommission,
  getRefusedReasons,
  acceptProviders,
  declineProviders,
  getDetailPublisher,
  getPublishersDetails,
  getDistributionMethods,
};
