const constants = {
  permissions: {
    linkAdvertiser: 'api:post:user:/users/resources/platform',
    addAdvertiserToUser: 'api:put:/users/add/advertisers',
    editAdvertiser: 'api:put:advertiser:/advertisers',
    createUser: 'api:post:user:/users',
    accounts: {
      advertisers: {
        manageUserColumn: 'frontend:advertiser:page:accounts:advertiser:column:manage_users',
        changeAdvertiserBlind: 'frontend:advertiser:page:accounts:advertiser:switch_blind',
        changeAdvertiserStatus: 'frontend:advertiser:page:accounts:advertiser:switch_status',
      },
      users: {
        rolesColumn: 'frontend:advertiser:page:accounts:user:column:roles',
        statusColumn: 'frontend:advertiser:page:accounts:user:column:status',
        linkUserToAdvertiser: 'frontend:advertiser:page:accounts:user:column:status',
      },
    },
    invoicing: {
      publisherCostColumn: 'frontend:advertiser:page:invoicing:column:publisher_cost',
      timeoneCostColumn: 'frontend:advertiser:page:invoicing:column:timeone_cost',
    },
    actions: {
      publisherNameColumn: 'frontend:advertiser:page:actions:column:publisher_name',
      publisherCommissionColumn: 'frontend:advertiser:page:actions:column:publisher_commission',
      commissionGroupColumn: 'frontend:advertiser:page:actions:column:commission_group',
      editAmount: 'frontend:advertiser:page:pending_actions:edit_amount',
      previousMonthValidation: 'api:actions|proxyperf:previous_month_validation',
    },
    cashback: {
      providerNameColumn: 'frontend:advertiser:page:cashback:column:provider_name',
    },
    emailing: {
      restrictedColumn: 'frontend:advertiser:page:emailing:column:restricted',
    },
    analytics: {
      consentChoiceAnalysisFilters: 'frontend:advertiser:page:analytics:consentChoiceAnalysis:filters',
      consentChoiceComparaisonFilters: 'frontend:advertiser:page:analytics:consentChoiceComparaisonFilters:filters',
    },
    consents: {
      publisherFilter: 'frontend:advertiser:page:consents:publisher:filter',
      typologyFilter: 'frontend:advertiser:page:consents:typology:filter',
    },
  },
  errors: {
    defaultMessage: 'Something went wrong. Please try again later.',
    noData: 'There is no data. Please try again later.',
  },
  featureFlagKeys: {
    feature: {
      homeEdition: 'front-advertiser-home-edition',
      requestMeasure: 'front-advertiser-request-measure',
      webVital: 'front-advertiser-web-vital-measure',
    },
    page: {
      cashback: 'front-advertiser-page-cashback',
      emailing: 'front-advertiser-page-emailing',
      highlights: 'front-advertiser-page-highlights',
      analytics: 'front-advertiser-page-analytics',
      statisticsByCampaign: 'front-advertiser-page-statistics-by-campaign',
    },
  },
  periodFormat: 'YYYY-MM-DD',
};

export default constants;
