import {
  getCashbackPendingApi,
  getCashbackHistoryApi,
  createExportCashbacksApi,
  getExportCashbacksApi,
  getCashbacksFileApi,
  uploadFileApi,
} from './cashback.api';
import { handleFetchResponse, handleFetchBlobResponse } from '../utils';

export const getCashbackPending = async payload => {
  const result = await getCashbackPendingApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const getCashbackHistory = async payload => {
  const result = await getCashbackHistoryApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const getExportCashbacks = async ({ campaignId, body, options, type }) => {
  const response = await createExportCashbacksApi({ campaignId, body, options, type });

  const { id: exportId } = await handleFetchResponse(response);

  return new Promise((resolve, reject) => {
    const computationChecker = setInterval(async () => {
      try {
        const exportResponse = await getExportCashbacksApi({ campaignId, id: exportId });
        const result = await handleFetchResponse(exportResponse);

        const { status, id, fileName } = result;

        if (status === 'Done') {
          clearInterval(computationChecker);
          resolve({ id, fileName });
        }

        if (status === 'Error') {
          throw new Error('Error while generated emailing shoots export');
        }
      } catch (error) {
        clearInterval(computationChecker);
        reject(error);
      }
    }, 1000);
  });
};

export const exportCashbacksPending = async (campaignId, body, options) => {
  const { id, fileName } = await getExportCashbacks({ campaignId, body, options, type: 'pending' });
  const response = await getCashbacksFileApi({ campaignId, id });

  const blob = await handleFetchBlobResponse(response);

  return { fileName, blob };
};

export const exportCashbackHistory = async (campaignId, body, options) => {
  const { id, fileName } = await getExportCashbacks({ campaignId, body, options, type: 'history' });
  const response = await getCashbacksFileApi({ campaignId, id });

  const blob = await handleFetchBlobResponse(response);

  return { fileName, blob };
};

export const uploadFile = async ({ campaignId, file }) => {
  const formData = new FormData();

  formData.append('file', file);

  const response = await uploadFileApi(campaignId, formData);

  return handleFetchResponse(response);
};
