import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Highlighter from 'react-highlight-words';
import { ThemeContext } from 'styled-components';
import { ApplicationLayoutContext, TO_CONSTANTS } from 'timeone-components';

import { SelectEntityContainer, SelectEntityLabel, SelectEntityPosition } from './styledComponents';
import { selectStyle, SelectSingleValue } from '../styledComponents';

const maxMenuHeight = {
  large: 280,
  defaultHeight: 210,
};

function formatOptionLabelSearch(option, { inputValue }) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={option.label}
      highlightStyle={{ backgroundColor: 'transparent', fontWeight: 700 }}
    />
  );
}

function SelectEntity({ entities, selectedEntity, setSelectedEntityFromId, label, slugEntity, formatOption }) {
  const theme = useContext(ThemeContext);
  const { media } = useContext(ApplicationLayoutContext);
  const { large, defaultHeight } = maxMenuHeight;
  const [options, setOptions] = useState([]);

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (entities) {
      setOptions(entities.map(formatOption));
    }
  }, [entities]);

  useEffect(() => {
    if (selectedEntity) {
      setValue(formatOption(selectedEntity));
    }
  }, [selectedEntity]);

  const handleChange = useCallback(entity => {
    setSelectedEntityFromId(entity.value);
  }, []);

  if (!entities?.length) {
    return null;
  }

  if (entities.length === 1) {
    const formatedEntity = formatOption(entities[0]);

    return (
      <SelectEntityContainer>
        <SelectEntityPosition>
          <SelectEntityLabel>{label}</SelectEntityLabel>
          <div data-onboarding={`select-${slugEntity}`}>
            <SelectSingleValue query={media.query}>
              <div title={formatedEntity.label}>{formatedEntity.label}</div>
            </SelectSingleValue>
          </div>
        </SelectEntityPosition>
      </SelectEntityContainer>
    );
  }

  return (
    <SelectEntityContainer>
      <SelectEntityPosition>
        <SelectEntityLabel>{label}</SelectEntityLabel>
        <div data-onboarding={`select-${slugEntity}`}>
          <Select
            className={`advertiser-header--select-${media.query}`}
            styles={selectStyle(theme, media.query, options)}
            isSearchable
            value={value}
            options={options}
            formatOptionLabel={formatOptionLabelSearch}
            onChange={handleChange}
            noOptionsMessage={() => 'No match'}
            blurInputOnSelect
            maxMenuHeight={media.query === TO_CONSTANTS.responsive.breakpointName.large ? large : defaultHeight}
          />
        </div>
      </SelectEntityPosition>
    </SelectEntityContainer>
  );
}

SelectEntity.defaultProps = {
  entities: [],
  selectedEntity: null,
};

SelectEntity.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({})),
  selectedEntity: PropTypes.shape({}),
  setSelectedEntityFromId: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  slugEntity: PropTypes.string.isRequired,
  formatOption: PropTypes.func.isRequired,
};

export default SelectEntity;
