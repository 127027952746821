import { getPendingBillingApi, getInvoicingApi } from './invoicing.api';
import { handleFetchResponse } from '../utils';

export const getPendingBilling = async (id, options = {}) => {
  const result = await getPendingBillingApi(id, options);

  return {
    period: result['meta:period'],
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
    total: result['meta:total'],
  };
};

export const getInvoicing = async (id, options = {}) => {
  const response = await getInvoicingApi(id, options);

  return handleFetchResponse(response);
};

export default { getPendingBilling, getInvoicing };
