import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableModular, tableModularParts } from 'timeone-components';

import CONSTANTS from './constants';

const {
  useTimeOneTable,
  useGetColumns,
  BodyRowDefault,
  HeaderRowDefault,
  HeaderCellDefault,
  HeaderRowSortable,
  FooterCellDefault,
  sortableHooks,
} = tableModularParts;

function getHeaderRow(sort) {
  function HeaderRow(props) {
    const { headerGroup } = props;

    return sort ? (
      <HeaderRowSortable key={headerGroup.getHeaderGroupProps().key} headerGroup={headerGroup} sort={sort} />
    ) : (
      <HeaderRowDefault {...props} />
    );
  }

  HeaderRow.propTypes = {
    headerGroup: PropTypes.shape({
      getHeaderGroupProps: PropTypes.func,
    }),
  };
  return HeaderRow;
}

function BodyRow(props) {
  return <BodyRowDefault {...props} rowHeight={`${CONSTANTS.table.rowHeight}px`} />;
}

function HeaderCell(props) {
  return <HeaderCellDefault {...props} headerHeight={`${CONSTANTS.table.headerHeight}px`} />;
}
function FooterCell(props) {
  return <FooterCellDefault {...props} footerHeight={`${CONSTANTS.table.footerHeight}px`} />;
}

export default function Table({ keys, items, onTableSort, total, noDataMessage, HeaderRow }) {
  const [columnsState, setColumnsState] = useState({ keys, HeaderCell, FooterCell });

  const columns = useGetColumns(columnsState);

  useEffect(() => {
    setColumnsState({ keys });
  }, [keys]);

  const hooks = useMemo(() => (onTableSort ? [...sortableHooks] : []), [onTableSort]);
  const footerData = useMemo(() => total, [total]);
  const instance = useTimeOneTable({
    columns,
    hooks,
    data: items,
    footerData,
  });

  return (
    <TableModular
      instance={instance}
      BodyRow={BodyRow}
      HeaderRow={HeaderRow || getHeaderRow(onTableSort)}
      height="100%"
      noDataMessage={noDataMessage}
    />
  );
}

Table.defaultProps = {
  onTableSort: null,
  total: null,
  noDataMessage: null,
};

Table.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onTableSort: PropTypes.func,
  total: PropTypes.shape({}),
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
