import styled from 'styled-components';
import { TO_CONSTANTS } from 'timeone-components';

const defaultValue = {
  controlBorderRadius: '1.5rem',
  controlHeight: '2.5rem',
  controlMinHeight: '2.5rem',
  controlWidth: 16,
  menuMarginTop: '0.25rem',
  menuWidth: '24.01rem',
  menuListBorderRadius: '0.67rem',
  menuPaddingScroll: '0.66rem 0.5rem 0.66rem 0.66rem ',
  menuPadding: 0.66,
  menuListPaddingRight: '0.35rem',
  optionPadding: '0.33rem 0.33rem',
  optionBorderRadius: '0.67rem',
  optionFontSize: '0.92rem',
  dropdownIndicatorPadding: 0.67,
  singleValueFontSize: '1rem',
  singleValuePadding: 'calc(1rem - 8px)',
  inputSingleAdvertiserPadding: 1,
  inputFontSize: '1rem',
};

const smallValue = {
  ...defaultValue,
  controlBorderRadius: '1rem',
  controlWidth: 12,
};

const extraSmallValues = {
  ...smallValue,
  dropdownIndicatorPadding: 0.5,
  singleValuePadding: 0,
  inputSingleAdvertiserPadding: 0.75,
  inputFontSize: '16px',
};

const largeValues = {
  ...defaultValue,
  controlBorderRadius: '1.125rem',
  controlHeight: '2.5rem',
  controlMinHeight: '2.5rem',
  controlWidth: 18,
  menuMarginTop: '0.31rem',
  menuWidth: '26.5rem',
  menuListBorderRadius: '0.625rem',
  menuPaddingScroll: '0.5rem 0.35rem 0.5rem 0.5rem ',
  menuPadding: 0.5,
  menuListPaddingRight: '0.3rem',
  optionPadding: '0.25rem 0.25rem',
  optionBorderRadius: '0.375rem',
  dropdownIndicatorPadding: 0.75,
  singleValueFontSize: '1.0625rem',
  optionFontSize: '1.0625rem',
};

const mediumValues = {
  ...defaultValue,
};

export function getResponsiveValues(query) {
  switch (query) {
    case TO_CONSTANTS.responsive.breakpointName.large:
      return largeValues;
    case TO_CONSTANTS.responsive.breakpointName.medium:
      return mediumValues;
    case TO_CONSTANTS.responsive.breakpointName.small:
      return smallValue;
    case TO_CONSTANTS.responsive.breakpointName.extraSmall:
      return extraSmallValues;
    default:
      return mediumValues;
  }
}

const selectStyle = (theme, query, options, withoutIndicator) => {
  const responsiveValues = getResponsiveValues(query);

  return {
    control: (base, state) => ({
      ...base,
      background: !state.isFocused ? theme.colors.selectDefaultGray : theme.colors.selectHoverGray,
      border: 'none',
      borderRadius: responsiveValues.controlBorderRadius,
      boxShadow: 'none',
      height: responsiveValues.controlHeight,
      minHeight: responsiveValues.controlMinHeight,
      width: `${responsiveValues.controlWidth}rem`,
      ':hover': {
        background: theme.colors.selectHoverGray,
        transition: 'all 0.5s ease',
        cursor: 'pointer',
      },
    }),
    singleValue: (singleValueBase, state) => ({
      ...singleValueBase,
      color: theme.colors.white,
      fontSize: responsiveValues.singleValueFontSize,
      marginLeft: 0,
      marginRight: 0,
      display: state && state.selectProps && state.selectProps.menuIsOpen ? 'none' : 'block',
      paddingLeft: responsiveValues.singleValuePadding,
    }),
    placeholder: placeholderBase => ({
      ...placeholderBase,
      padding: 0,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    menu: menuBase => ({
      ...menuBase,
      borderRadius: responsiveValues.menuListBorderRadius,
      boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.24)',
      marginTop: responsiveValues.menuMarginTop,
      width: responsiveValues.menuWidth,
      padding: options.length < 11 ? `${responsiveValues.menuPadding}rem` : responsiveValues.menuPaddingScroll,
    }),
    menuList: menuListBase => ({
      ...menuListBase,
      paddingRight: options.length < 11 ? 0 : responsiveValues.menuListPaddingRight,
      paddingTop: 0,
      paddingBottom: 0,
      scrollbarColor: `${theme.colors.scrollBar} ${theme.colors.white}`,
      scrollbarWidth: 'thin',
      scrollbarArrowColor: theme.colors.scrollBar,
      scrollbarFaceColor: theme.colors.scrollBar,
      scrollbarShadowColor: theme.colors.scrollBar,
      '::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.colors.scrollBar,
      },
      '::-webkit-scrollbar-track': {
        borderRadius: responsiveValues.menuListBorderRadius,
        background: `${theme.colors.scrollBarLight}`,
        border: `0.17rem solid ${theme.colors.white}`,
      },
    }),
    option: optionBase => ({
      ...optionBase,
      backgroundColor: theme.colors.white,
      color: theme.primary,
      fontSize: responsiveValues.optionFontSize,
      padding: responsiveValues.optionPadding,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.hoverMenuList,
        borderRadius: responsiveValues.optionBorderRadius,
      },
    }),
    indicatorSeparator: indicatorSeparatorBase => ({
      ...indicatorSeparatorBase,
      display: 'none',
    }),
    dropdownIndicator: dropdownIndicatorBase => ({
      ...dropdownIndicatorBase,
      color: theme.colors.white,
      display: withoutIndicator ? 'none' : 'flex',
      cursor: 'pointer',
      padding: `0 ${responsiveValues.dropdownIndicatorPadding}rem`,
      ':hover': {
        color: theme.colors.white,
      },
      '> svg': { width: '1rem' },
    }),
    input: inputBase => ({
      ...inputBase,
      color: theme.colors.white,
      paddingLeft: responsiveValues.singleValuePadding,
      fontSize: responsiveValues.inputFontSize,
    }),
  };
};

const SingleAdvertiser = styled.div.attrs(({ query }) => ({
  rspV: getResponsiveValues(query),
}))`
  align-items: center;
  background: ${({ theme }) => theme.colors.selectDefaultGray};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: ${({ rspV }) => rspV.controlHeight};
  padding: 0 ${({ rspV }) => rspV.inputSingleAdvertiserPadding}rem;
  width: ${({ rspV }) => rspV.controlWidth - 2 * rspV.inputSingleAdvertiserPadding}rem;
`;

export { selectStyle, SingleAdvertiser };
