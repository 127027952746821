import styled, { css } from 'styled-components';

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  &:hover {
    .input-check-mark {
      background-color: ${({ theme }) => theme.input.checkbox.hover.backgroundColor};
      box-shadow: '0 1px 3px 0 rgb(0 0 0 10%), 0 2px 1px -1px rgb(0 0 0 5.6%), 0 1px 1px 0 rgb(0 0 0 14%)';
    }
  }
`;

export const InputCheckmark = styled.div`
  background-color: ${({ theme }) => theme.input.checkbox.backgroundColor};
  border: solid ${({ theme }) => theme.input.checkbox.active.backgroundColor} 2px;
  border-radius: 3px;
  box-shadow: '0 1px 3px 0 rgb(0 0 0 10%), 0 2px 1px -1px rgb(0 0 0 5.6%), 0 1px 1px 0 rgb(0 0 0 14%)';
  height: 1rem;
  margin-right: 0.5rem;
  position: relative;
  width: 1rem;
  min-height: 1rem;
  min-width: 1rem;

  &::after {
    border: solid 2px ${({ theme }) => theme.input.checkbox.active.fontColor};
    border-left: 0;
    border-top: 0;
    content: ' ';
    display: none;
    height: 0.6rem;
    left: 0.25rem;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 0.3rem;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.input.checkbox.active.backgroundColor};

      &:after {
        display: block;
      }
    `}
`;
