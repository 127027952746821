import { token } from 'timeone-components';

const rolesAssignablesUrl = `${process.env.REACT_APP_TIMEONE_SECURITY_API}/roles/assignable`;
const rolesUrl = `${process.env.REACT_APP_TIMEONE_SECURITY_API}/roles`;

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getAvailableRoleForResourceApi = ({ id, type }) =>
  fetch(`${rolesAssignablesUrl}?resourceId=${encodeURIComponent(id)}&resourceType=${type}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getRolesApi = () =>
  fetch(rolesUrl, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export default { getAvailableRoleForResourceApi };
