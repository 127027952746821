// import posthog from 'posthog-js';
import { fetchInterceptor } from 'timeone-components';

// import { isFeatureEnabled, isPosthogActive } from '../utils';
// import CONSTANTS from '../constants';

// const digits = 'xxx';
// const ignoredDomains = [process.env.REACT_APP_POSTHOG_HOST];
// const ignoredUrls = [
//   `GET ${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${digits}/actions/process`,
//   `GET ${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${digits}/export_actions`,
// ];

// function getMarker({ url, method = 'GET', body = '' } = {}) {
//   try {
//     return btoa(`${method}:${url}:${body}`);
//   } catch (e) {
//     console.error(e);
//     return 'ko';
//   }
// }

// function isExclude({ method = 'GET', url = '' } = {}) {
//   try {
//     const { protocol, host, pathname } = new URL(url);
//     const testUrl = `${method} ${protocol}//${host}${pathname}`.replace(/\d+/g, digits);

//     return ignoredDomains.includes(host) || ignoredUrls.includes(testUrl);
//   } catch (e) {
//     return true;
//   }
// }

// function sendToPPosthog(measure, { url, method, body } = {}) {
//   if (isPosthogActive()) {
//     const urlData = new URL(url);
//     const requestMethod = method || 'GET';
//     const data = {
//       requestMethod,
//       requestBody: body,
//       requestDuration: measure.duration,
//       requestUrl: urlData.href,
//       requestHost: urlData.host,
//       requestPath: `${requestMethod} ${urlData.pathname}`.replace(/\d+/g, digits),
//       requestPathname: urlData.pathname,
//       requestSearch: urlData.search,
//     };

//     posthog.capture(`request:measure`, data);
//   }
// }

// function hookFecthToMakeMeasure() {
//   const originalFetch = window.fetch;
//   const maxErrors = 5;

//   // eslint-disable-next-line sonarjs/cognitive-complexity
//   window.fetch = (function hookFetch(localFetch) {
//     let errors = 0;

//     function startMeasure(marker, config) {
//       if (errors > maxErrors || isExclude(config)) {
//         return;
//       }

//       try {
//         performance.mark(marker);
//       } catch (e) {
//         errors += 1;
//         console.warn(e);
//       }
//     }

//     function getMeasureAndClean(response, marker, config) {
//       if (errors > maxErrors || isExclude(config)) {
//         return;
//       }

//       const measureName = Math.random();

//       try {
//         performance.measure(measureName, marker);
//         const [measure] = performance.getEntriesByName(measureName, 'measure');

//         if (measure) {
//           sendToPPosthog(measure, config);
//         }

//         performance.clearMeasures(measureName);
//         performance.clearMarks(marker);

//         errors = 0;
//       } catch (e) {
//         errors += 1;
//         console.warn(e);
//       }
//     }

//     return function customFetch(...args) {
//       const [url, config] = args;
//       const configForMeasure = { url, ...(config || {}) };
//       const marker = getMarker(configForMeasure);

//       startMeasure(marker, configForMeasure);
//       return localFetch.apply(this, args).then(response => {
//         if (typeof response.status === 'number' && response.status < 400) {
//           getMeasureAndClean(response, marker, configForMeasure);
//         }

//         return response;
//       });
//     };
//   })(originalFetch);
// }

export function init() {
  // if (isFeatureEnabled(CONSTANTS.featureFlagKeys.feature.requestMeasure)) {
  //   hookFecthToMakeMeasure();
  // }

  fetchInterceptor.init();
}

export default { init };
