import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const ContainerNoAvailableImage = styled.div`
  display: block;
  max-width: 300px;
  margin: 0 auto;
  height: ${({ height }) => height};
  background-color: ${({ theme }) => lighten(0.27, theme.colors.gray)};
  box-sizing: border-box;
  border-radius: 0.8rem;
  border: solid 1px ${({ theme }) => lighten(0.27, theme.colors.gray)};
  padding: 1rem;
`;

export const ErrorMessageNoAvailableImage = styled.p`
  font-size: 2.5rem;
  color: ${({ theme }) => darken(0.1, theme.colors.gray)};
  font-weight: 500;
  text-align: center;
`;

export const NoAvailableImage = styled.div`
  width: 100%;
  height: 400px;
  background-color: ${({ theme }) => theme.colors.gray};
  box-sizing: border-box;
  border-radius: 0.8rem;
  border: solid 1px ${({ theme }) => lighten(0.2, theme.colors.gray)};
  margin-bottom: 1.3rem;
`;

export const ContainerNoAvailableImageIcon = styled.div`
  width: 6rem;
  height: auto;
  margin: 0 auto 1rem;
  fill: ${({ theme }) => lighten(0.2, theme.colors.gray)};
`;

export const ContainerContentNoAvailableImage = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
