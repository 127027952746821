import React, { useState, useCallback } from 'react';

import { InputCheckmark, Label } from './styledComponents';

export default function useControlledCheckbox({ id, label }) {
  const [state, setState] = useState(false);

  function onChange() {
    setState(v => !v);
  }

  const Checkbox = useCallback(
    () => (
      <Label htmlFor={id}>
        <InputCheckmark active={state} />
        <input id={id} type="checkbox" checked={state} onChange={onChange} /> {label}
      </Label>
    ),
    [label, state]
  );

  return {
    state,
    Checkbox,
  };
}
