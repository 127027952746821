/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SearchInput = styled.input`
  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  color: ${({ theme }) => theme.primary};
  margin: 1rem;
`;
