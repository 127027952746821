import styled from 'styled-components';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10rem;
`;

export const ErrorMessage = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

export const RedirectLink = styled.div`
  text-decoration: underline;
  font-size: 1.5rem;
  margin-top: 1rem;
`;

export default ErrorPageContainer;
