import { useCallback } from 'react';
import { actions } from 'react-table';

const ACTIONS = {
  setTimeoneActionRunning: 'setTimeoneActionRunning',
};

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  if (action.type === actions.init) {
    return {
      timeoneActionRunning: false,
      ...state,
    };
  }

  if (action.type === ACTIONS.setTimeoneActionRunning) {
    return {
      ...state,
      timeoneActionRunning: !!action.value,
    };
  }
}

function useInstance(instance) {
  const { dispatch } = instance;
  const setTimeoneActionRunning = useCallback(
    value => dispatch({ type: ACTIONS.setTimeoneActionRunning, value }),
    [dispatch]
  );

  Object.assign(instance, {
    setTimeoneActionRunning,
  });
}

export default function useTimeoneAction(hooks) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
}

useTimeoneAction.pluginName = 'useTimeoneAction';
