import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../Providers/User.provider';
import { getRouteIdByPermissions } from '../../App/useRouter';

export default function Users() {
  const { permissions } = useContext(UserContext);
  const permissionsIds = getRouteIdByPermissions(permissions);

  return permissionsIds?.includes('users') ? (
    <Link to="/users" type="button">
      Users
    </Link>
  ) : null;
}
