import { token } from 'timeone-components';

import { queryParams, fetchWithPagination } from '../utils';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

export const getPendingBillingApi = (campaignId, options) =>
  fetchWithPagination(`${process.env.REACT_APP_PROXY_PERF_API}/campaign_pending_action/${campaignId}`, {
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getInvoicingApi = (advetiserId, options) =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/advertiser_invoices/${advetiserId}?${queryParams(options)}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export default { getPendingBillingApi, getInvoicingApi };
