import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'timeone-components';

import { useControlledCheckbox } from '../../../hooks';
import { Button } from './styledComponents';

import { DISPLAY_TYPE } from '../constants';

export default function TemplateModal({ data, onView, next, Content }) {
  const [modalProps, setModalProps] = useState({ isOpen: true });
  const onClose = useCallback(() => {
    setModalProps({ isOpen: false });
  }, []);

  useEffect(() => {
    setModalProps({ isOpen: true });
  }, [data.id]);

  const { state, Checkbox } = useControlledCheckbox({
    id: `notification_${data.id}`,
    label: 'I have seen this notification *',
  });
  const isBlocked = data.displayType === DISPLAY_TYPE.blocked;
  const onClick = useCallback(() => {
    onClose();
    onView();
    next();
  }, [onClose, onView, next]);

  return (
    <Modal {...modalProps}>
      <Content {...data} />
      {isBlocked ? (
        <>
          <Checkbox />
          <br />
        </>
      ) : null}
      <Button fill disabled={isBlocked && !state} onClick={onClick}>
        Close
      </Button>
    </Modal>
  );
}
