import React, { useContext } from 'react';

import SelectEntity from '../SelectEntity';
import { AdvertisersContext } from '../../Providers';
import { SelectContainer } from './styledComponents';

function formatOption(option) {
  return { label: `${option.name} (#${option.legacyId})`, value: option.id };
}
function SelectCampaign() {
  const { campaigns, selectedCampaign, setSelectedCampaignFromId } = useContext(AdvertisersContext);

  return (
    <SelectContainer>
      <SelectEntity
        entities={campaigns}
        selectedEntity={selectedCampaign}
        setSelectedEntityFromId={setSelectedCampaignFromId}
        label="Campaign"
        slugEntity="campaign"
        formatOption={formatOption}
      />
    </SelectContainer>
  );
}
export default SelectCampaign;
