import { gql } from '@apollo/client';

export const SEARCH_ADVERTISER_WITH_PLATFORM = gql`
  query getAdvertisers($options: OptionsInput) {
    getAdvertisers(options: $options) {
      id
      name
      legacyId
      status
      platform {
        id
        name
      }
      roles {
        name
        publicName
      }
      isBlind
      permissions
    }
  }
`;

export const GET_ADVERTISER = gql`
  query getAdvertiser($advertiserId: ID!, $userId: ID) {
    getAdvertiser(advertiserId: $advertiserId, userId: $userId) {
      id
      name
      legacyId
      status
      platform {
        id
        name
      }
      roles {
        name
        publicName
      }
      isBlind
      permissions
    }
  }
`;

export const GET_ADVERTISER_USERS = gql`
  query getAdvertiserUsers($advertiserId: ID!, $options: OptionsInput) {
    getAdvertiserUsers(advertiserId: $advertiserId, options: $options) {
      id
      name
      firstName
      lastName
      status
      email
      profilePicture
      company
      roles {
        id
        name
        publicName
      }
    }
  }
`;

export const GET_ADVERTISER_ASSIGNABLE_RESOURCES_BY_ROLES = gql`
  query getAdvertiserAssignableResourcesByRoles($advertiserId: ID!, $userId: ID!) {
    getAdvertiserAssignableResourcesByRoles(advertiserId: $advertiserId, userId: $userId) {
      id
      name
      publicName
      description
      product
      assignableCampaigns {
        id
        name
        legacyId
        legacyLabel
      }
    }
  }
`;

export default {
  SEARCH_ADVERTISER_WITH_PLATFORM,
  GET_ADVERTISER,
  GET_ADVERTISER_USERS,
  GET_ADVERTISER_ASSIGNABLE_RESOURCES_BY_ROLES,
};
