/* eslint-disable camelcase */
import { token } from 'timeone-components';

import { stitchingClient } from '../utils';
import { GET_EMAILING_SHOOTS } from './emailing.query';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getEmailingShootsApi = ({ campaignId, sendDate_after, sendDate_before }) =>
  campaignId
    ? stitchingClient.query({
        name: 'emailingShoots',
        query: GET_EMAILING_SHOOTS,
        variables: { campaignId, sendDate_after, sendDate_before },
      })
    : null;

export const createExportEmailingShootsApi = ({ campaignId, body }) =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/campaigns/${campaignId}/export_emailing_shoots`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const getExportEmailingShootsApi = ({ campaignId, id }) =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/campaigns/${campaignId}/export_emailing_shoots/${id}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getEmailingShootsFileApi = ({ campaignId, id }) =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/campaigns/${campaignId}/export_emailing_shoots/${id}/download`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
