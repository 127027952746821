import React, { useState, useMemo, useCallback } from 'react';
import { Card, Pagination, TableContainer, toast } from 'timeone-components';
import debounce from 'lodash/debounce';

import { userService } from '../../services';
import { useListRetention } from '../../hooks';
import { PageContainer, Loader, Table, NoData } from '../../components';
import { SetUserStatus } from './components';
import { captureException } from '../../utils';
import { SearchInput } from './styledComponents';
import USERS from './constants';

const defaultOrder = { 'orderBy[email]': 'ASC' };

function UserLoader() {
  return <Loader>Fetching users</Loader>;
}

export default function Users() {
  const [search, setSearch] = useState();
  const [reloadKey, setReloadKey] = useState(Math.random());
  const [order, setOrder] = useState(defaultOrder);

  const { retentionPage, onPageChange, onSearchChange } = useListRetention('pendingProvidersList');

  const updateStatus = useCallback(async (userId, email, status) => {
    try {
      await userService.updateUserStatus(userId, status);

      setReloadKey(Math.random());
      toast.success(`${email} has been ${status ? 'activated' : 'deactivated'}`);
    } catch (e) {
      toast.error(`Error while ${status ? 'activate' : 'deactivate'} ${email}. Please try later.`);
      captureException({ error: e, type: e.type || 'fecthData', component: 'Users' });
    }
  }, []);

  const ActionButtonCellRenderer = useCallback(
    props => <SetUserStatus {...props} updateStatus={updateStatus} />,
    [updateStatus]
  );

  const keys = useMemo(
    () => [
      {
        label: 'Firstname',
        dataKey: 'firstName',
        width: 75,
        align: 'left',
      },
      {
        label: 'Lastname',
        dataKey: 'lastName',
        width: 75,
        align: 'left',
      },
      {
        label: 'Email',
        dataKey: 'email',
        width: 75,
        align: 'left',
      },
      {
        label: '',
        dataKey: 'actions',
        width: 50,
        align: 'center',
        cellRenderer: ActionButtonCellRenderer,
        disableSort: true,
      },
    ],
    []
  );

  const onTableSort = useCallback(({ sortBy, sortDirection }) => {
    const orderBy = `orderBy[${sortBy}]`;
    const newOrder = { [orderBy]: sortDirection };

    setOrder(newOrder);
  }, []);

  const handleChange = debounce(value => {
    onSearchChange(value);
    setSearch(value);
  }, 500);

  const fetchData = useCallback(
    async ({ limit, offset }) => {
      const searchOptions = search
        ? {
            search,
          }
        : {};

      const { items, totalItem } = await userService.getUsers({
        itemsPerPage: USERS.pagination.numberToDisplayPerPage,
        limit,
        offset,
        ...order,
        ...searchOptions,
      });

      return { totalItem, items };
    },
    [search, order, reloadKey]
  );

  return (
    <PageContainer>
      <Card title="Users">
        <SearchInput
          type="search"
          name="searchUser"
          defaultValue={search}
          placeholder="Search..."
          onChange={evt => handleChange(evt.target.value)}
        />
        <div>
          <Pagination
            fetchData={fetchData}
            initialPage={retentionPage}
            onPageChange={onPageChange}
            options={USERS.pagination}
            render={({ items }) => (
              <TableContainer
                rowHeight={USERS.table.rowHeight}
                headerHeight={USERS.table.headerHeight}
                footerHeight={USERS.table.footerHeight}
                numberOfEntries={items?.length > 0 ? items?.length : 15}
                maxLine={USERS.table.maxLineToDisplay}
              >
                <Table
                  items={items}
                  keys={keys}
                  onTableSort={onTableSort}
                  noDataMessage={search ? <NoData search={search} /> : 'There is no user you can access.'}
                />
              </TableContainer>
            )}
            loader={UserLoader}
          />
        </div>
      </Card>
    </PageContainer>
  );
}
