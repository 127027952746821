import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'moment/locale/en-gb';
import 'react-dates/initialize';
import 'timeone-components/lib/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import { setFrameworkConfig } from 'timeone-components';
import { Link } from 'react-router-dom';

import { AdvertisersProvider, UserProvider, AdvertiserSelectProvider, DashboardPeriodProvider } from './Providers';
import App from './App';
import {
  fetchInterceptor,
  // webVital,
  posthog,
  sentry,
} from './tools';
import { UsersLink } from './pages/Users';
import { unregister } from './registerServiceWorker';
import { disableWarnProptypeInNavigator } from './utils';
import './index.css';

moment.locale('en-gb');
disableWarnProptypeInNavigator();
setFrameworkConfig({
  modules: { userInformations: true },
  urls: {
    graphqlUserUrl: process.env.REACT_APP_GRAPHQL_USER_API_URL,
    userResourceUrl: process.env.REACT_APP_USER_RESOURCE_API_URL,
    authenticationUrl: `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/oauth`,
    oauthTokenUrl: `${process.env.REACT_APP_OAUTH2_SERVER_URL}/oauth/verify`,
    cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN_URL,
  },
  components: {
    userHeaderLinks: [
      () => (
        <Link to="/accounts" type="button">
          Accounts
        </Link>
      ),
      UsersLink,
    ],
  },
});

// Must be declare after the setFrameworkConfig method as it require frameworkConfig
posthog.init();
// sentry, fetchInterceptor and webVital use posthog feature so it MUST be called after posthog.init()
sentry.init();
fetchInterceptor.init();
// webVital.init();

const root = createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <UserProvider>
    <AdvertisersProvider>
      <AdvertiserSelectProvider>
        <DashboardPeriodProvider>
          <App />
        </DashboardPeriodProvider>
      </AdvertiserSelectProvider>
    </AdvertisersProvider>
  </UserProvider>
  // </React.StrictMode>
);
unregister();
