/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_EMAILING_SHOOTS = gql`
  query collectionQueryEmailingShoots($campaignId: ID!, $sendDate_after: Date, $sendDate_before: Date) {
    collectionQueryEmailingShoots(
      campaignId: $campaignId
      sendDate_after: $sendDate_after
      sendDate_before: $sendDate_before
    ) {
      shootId
      volume
      sendDate
      status
      commissionGroup {
        _id
        name
      }
      provider {
        _id
        name
        legacyId
      }
      kitUrl
      promos {
        promoid
        promoDesc
      }
    }
  }
`;

export const GET_AUTOCOMPLETE_EMAILING_SHOOTS_ID = gql`
  query collectionQueryEmailingShoots($campaignId: ID!) {
    collectionQueryEmailingShoots(campaignId: $campaignId) {
      shootId
    }
  }
`;

export const GET_AUTOCOMPLETE_KITS = gql`
  query collectionQueryEmailingShoots($campaignId: ID!) {
    collectionQueryEmailingShoots(campaignId: $campaignId) {
      promos {
        promoid
        promoDesc
      }
    }
  }
`;
