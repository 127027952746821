/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Title = styled('h1')`
  display: flex;
  font-size: 2.5rem;

  svg {
    height: 2.75rem;
    width: 2.75rem;
    margin-right: 1rem;
    color: ${({ theme }) => theme.colors.red};
  }
`;
