import posthog from 'posthog-js';

import { isFeatureEnabled, isPosthogActive } from '../utils';
import reportWebVitals from '../reportWebVitals';
import CONSTANTS from '../constants';

function sendMeasure({ id, name, value, delta } = {}) {
  const webVital = {
    id,
    name,
    value,
    delta,
  };

  if (isPosthogActive()) {
    posthog.capture(`web-vital:measure`, webVital);
  } else {
    console.log({ [`web-vital-${name}`]: webVital });
  }
}

export function init() {
  if (isFeatureEnabled(CONSTANTS.featureFlagKeys.feature.webVital)) {
    reportWebVitals(sendMeasure);
  }
}

export default { init };
