import { token } from 'timeone-components';

import { client, fetchWithPagination } from '../utils';
import { GET_USER_RESOURCES, GET_ME, SEARCH_USER } from './user.query';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

export const getLogout = () =>
  fetch(`${process.env.REACT_APP_OAUTH2_SERVER_URL}/logout`, {
    credentials: 'include',
    method: 'GET',
  });

export const getUserResourcesApi = id =>
  client.query({
    name: 'getUserResources',
    query: GET_USER_RESOURCES,
    variables: { id },
  });

export const getMeApi = () =>
  client.query({
    name: 'getMe',
    query: GET_ME,
  });
export const getUsersApi = options =>
  fetchWithPagination(`${process.env.REACT_APP_USER_RESOURCE_API_URL}/users`, {
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const createUserPreferenceApi = (key, value) =>
  fetch(`${process.env.REACT_APP_USER_PREFERENCE_API_URL}/advertiser/user_preferences`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify({ key, value: JSON.stringify(value) }),
  });

export const updateUserPreferenceApi = (id, key, value) =>
  fetch(`${process.env.REACT_APP_USER_PREFERENCE_API_URL}/advertiser/user_preferences/${id}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify({ key, value: JSON.stringify(value) }),
  });

export const getUserPreferenceApi = key =>
  fetch(`${process.env.REACT_APP_USER_PREFERENCE_API_URL}/advertiser/user_preferences?key=${key}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const deleteUserPreferenceApi = id =>
  fetch(`${process.env.REACT_APP_USER_PREFERENCE_API_URL}/advertiser/user_preferences/${id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const searchUserApi = ({ email, resourceId }) =>
  client.query({
    name: 'searchUser',
    query: SEARCH_USER,
    variables: { email, resourceType: 'advertiser', resourceId },
  });

export const getAssignableRolesApi = (id, resourceType) =>
  fetch(
    `${process.env.REACT_APP_TIMEONE_SECURITY_API}/roles/assignable?resourceId=${id}&resourceType=${resourceType}`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const updateUserAdvertiserRolesApi = (userId, body) =>
  fetch(`${process.env.REACT_APP_RESOURCE_TIMEONE_API_URL}/users/${userId}/add/advertisers`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const deleteUserAdvertiserRolesApi = (userId, body) =>
  fetch(`${process.env.REACT_APP_RESOURCE_TIMEONE_API_URL}/users/${userId}/delete/advertisers`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const createUserApi = body =>
  fetch(`${process.env.REACT_APP_USER_RESOURCE_API_URL}/users`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const getUserCampaignsRolesApi = ({ userId, advertiserId }) =>
  fetch(
    `${process.env.REACT_APP_RESOURCE_TIMEONE_API_URL}/user_has_campaigns?serviceAdvertiserId=${advertiserId}&userRole.userId=${userId}&pagination=false`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const putUserStatusApi = (id, body) =>
  fetch(`${process.env.REACT_APP_USER_RESOURCE_API_URL}/users/${id}/status`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export default {
  createUserApi,
  createUserPreferenceApi,
  deleteUserAdvertiserRolesApi,
  deleteUserPreferenceApi,
  getAssignableRolesApi,
  getLogout,
  getMeApi,
  getUserCampaignsRolesApi,
  getUserPreferenceApi,
  getUserResourcesApi,
  getUsersApi,
  putUserStatusApi,
  searchUserApi,
  updateUserAdvertiserRolesApi,
  updateUserPreferenceApi,
};
