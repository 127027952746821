import React from 'react';
import { assets } from 'timeone-components';

import {
  ContainerNoAvailableImage,
  ContainerNoAvailableImageIcon,
  ErrorMessageNoAvailableImage,
} from './styledComponents.js';

const { NoAvailableImageIcon } = assets.icons;

export function NoAvailableImage({ height }) {
  return (
    <ContainerNoAvailableImage height={height}>
      <ContainerNoAvailableImageIcon>
        <NoAvailableImageIcon disabled />
      </ContainerNoAvailableImageIcon>
      <ErrorMessageNoAvailableImage>Not Available</ErrorMessageNoAvailableImage>
    </ContainerNoAvailableImage>
  );
}

export default NoAvailableImage;
