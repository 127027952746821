export function resetLists() {
  localStorage.setItem('lists', JSON.stringify({}));
}

export function getListPage(listKey) {
  const listStorage = JSON.parse(localStorage.getItem('lists'));

  return listStorage?.[listKey]?.page;
}

export function setListPage(listKey, page) {
  const listStorage = JSON.parse(localStorage.getItem('lists'));

  localStorage.setItem(
    'lists',
    JSON.stringify({
      ...listStorage,
      [listKey]: {
        ...listStorage[listKey],
        page,
      },
    })
  );
}

export function getListSearch(listKey) {
  const listStorage = JSON.parse(localStorage.getItem('lists'));

  return listStorage?.[listKey]?.search;
}

export function setListSearch(listKey, search) {
  const listStorage = JSON.parse(localStorage.getItem('lists'));

  localStorage.setItem(
    'lists',
    JSON.stringify({
      ...listStorage,
      [listKey]: {
        ...listStorage[listKey],
        search,
      },
    })
  );
}
