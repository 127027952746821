import { token } from 'timeone-components';
import { queryParams } from '../utils';

const contactsPlatformApiUrl = `${process.env.REACT_APP_PLATFORM_API_URL}/contacts`;
const campaignTeamsApiUrl = `${process.env.REACT_APP_TEAMS_API_URL}/campaign_teams`;
const servicePlatformId = 1;

export const getPlatformContactsApi = tagsLabel => {
  const params = {
    'platforms.id': servicePlatformId,
  };

  if (tagsLabel) {
    params['tags.label'] = tagsLabel;
  }

  const url = `${contactsPlatformApiUrl}?${queryParams(params)}`;

  return fetch(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
};

export const getCampaignTeamsApi = (serviceAdvertiserId, serviceCampaignId) => {
  const params = {
    'platforms.id': servicePlatformId,
    serviceAdvertiserId,
    serviceCampaignId,
  };

  const url = `${campaignTeamsApiUrl}?${queryParams(params)}`;

  return fetch(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
};

export default {
  getPlatformContactsApi,
  getCampaignTeamsApi,
};
