const lineHeight = 36;

export default {
  pagination: {
    numberToDisplayPerPage: 30,
  },
  table: {
    rowHeight: lineHeight,
    headerHeight: lineHeight,
    footerHeight: lineHeight,
    maxLineToDisplay: 15,
  },
};
