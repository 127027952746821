import builder from './base';

const STAT_PERF_ROOT_URL = `${process.env.REACT_APP_STAT_API_URL}/performance/advertiser`;
const STAT_PERF_CONF_URL = `${STAT_PERF_ROOT_URL}/config`;
const STAT_PERF_CONF_BLIND_URL = `${STAT_PERF_ROOT_URL}/b/config`;
const STAT_PERF_EMAILING_CONF_URL = `${STAT_PERF_ROOT_URL}/emailing/config`;
const STAT_PERF_EMAILING_CONF_BLIND_URL = `${STAT_PERF_ROOT_URL}/emailing/b/config`;
const STAT_PERF_CONSENT_CONF_URL = `${STAT_PERF_ROOT_URL}/consent/config`;
const STAT_PERF_CONSENT_CONF_BLIND_URL = `${STAT_PERF_ROOT_URL}/consent/b/config`;
const STAT_PERF_QUERY_URL = `${STAT_PERF_ROOT_URL}/query`;
const STAT_PERF_CONSENT_QUERY_URL = `${STAT_PERF_ROOT_URL}/consent/query`;
const STAT_PERF_EXPORT_URL = `${STAT_PERF_ROOT_URL}/export`;
const STAT_PERF_EXPORT_STATUS_URL = `${STAT_PERF_ROOT_URL}/export/status`;
const STAT_PERF_EXPORT_DOWNLOAD_URL = `${STAT_PERF_ROOT_URL}/export/download`;

export default {
  fetchConfig: builder.buildFetchConfig(STAT_PERF_CONF_URL),
  fetchConfigBlind: builder.buildFetchConfig(STAT_PERF_CONF_BLIND_URL),
  fetchConfigByAdvertiserId: advertiserId => builder.buildFetchConfigByAdvertiserId(STAT_PERF_CONF_URL, advertiserId),
  fetchConfigByAdvertiserIdBlind: advertiserId =>
    builder.buildFetchConfigByAdvertiserId(STAT_PERF_CONF_BLIND_URL, advertiserId),
  fetchConfigByAdvertiserIdEmailing: advertiserId =>
    builder.buildFetchConfigByAdvertiserId(STAT_PERF_EMAILING_CONF_URL, advertiserId),
  fetchConfigByAdvertiserIdEmailingBlind: advertiserId =>
    builder.buildFetchConfigByAdvertiserId(STAT_PERF_EMAILING_CONF_BLIND_URL, advertiserId),
  fetchConfigByAdvertiserIdConsent: advertiserId =>
    builder.buildFetchConfigByAdvertiserId(STAT_PERF_CONSENT_CONF_URL, advertiserId),
  fetchConfigByAdvertiserIdConsentBlind: advertiserId =>
    builder.buildFetchConfigByAdvertiserId(STAT_PERF_CONSENT_CONF_BLIND_URL, advertiserId),
  fetchData: builder.buildFetchData(STAT_PERF_QUERY_URL),
  fetchConsentData: builder.buildFetchData(STAT_PERF_CONSENT_QUERY_URL),
  requestExportFile: builder.buildRequestExportFile(STAT_PERF_EXPORT_URL),
  fetchExportStatus: builder.buildFetchExportStatus(STAT_PERF_EXPORT_STATUS_URL),
  EXPORT_DOWNLOAD_BASE_URL: STAT_PERF_EXPORT_DOWNLOAD_URL,
};
