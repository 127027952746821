import styled from 'styled-components';

export const SelectEntityContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    align-self: flex-end;
  }
`;

export const SelectEntityPosition = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SelectEntityLabel = styled.div`
  margin-right: 1rem;
  font-weight: 700;
  align-self: center;
`;

export default { SelectEntityContainer };
