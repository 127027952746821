import { useState } from 'react';
import localStorageService from '../services/localStorage';

export default function useListRetention(listName) {
  const [retentionPage, setRetentionPage] = useState(localStorageService.getListPage(listName) || 1);
  const [retentionSearch] = useState(localStorageService.getListSearch(listName) || '');

  function onSearchChange(value) {
    localStorageService.setListSearch(listName, value);
    localStorageService.setListPage(listName, 1);
    setRetentionPage(1);
  }

  function onPageChange(page) {
    localStorageService.setListPage(listName, page);
    setRetentionPage(page);
  }

  function onOrderChange() {
    localStorageService.setListPage(listName, 1);
    setRetentionPage(1);
  }

  return { retentionPage, retentionSearch, onSearchChange, onPageChange, onOrderChange };
}
