import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorMessage, ErrorPageContainer, RedirectLink } from './styledComponents';

export default function Error404() {
  return (
    <ErrorPageContainer>
      <p>404</p>
      <ErrorMessage>Page not found</ErrorMessage>
      <RedirectLink>
        <Link to="/" type="button">
          Go to homepage
        </Link>
      </RedirectLink>
    </ErrorPageContainer>
  );
}
