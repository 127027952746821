import { token } from 'timeone-components';

import { client, queryGraphqlWithPagination, fetchWithPagination, queryParams } from '../utils';
import {
  SEARCH_ADVERTISER_WITH_PLATFORM,
  GET_ADVERTISER,
  GET_ADVERTISER_USERS,
  GET_ADVERTISER_ASSIGNABLE_RESOURCES_BY_ROLES,
} from './advertiser.query';

const userApiUrl = `${process.env.REACT_APP_USER_RESOURCE_API_URL}/users`;
const advertiserApiUrl = `${process.env.REACT_APP_ADVERTISER_API_URL}/advertisers`;
const userHasAdvertiserUrl = `${process.env.REACT_APP_RESOURCE_TIMEONE_API_URL}/user_has_advertisers`;

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

const servicePlatformId = 1;

export const postAdvertiserResources = (userId, body) =>
  fetch(`${userApiUrl}/${userId}/resources/platform`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const searchAdvertisersApi = (search, options = {}) => {
  const minimalOptions = { limit: 20, offset: 0 };
  const { limit, offset } = minimalOptions;

  let page = 1;

  if (offset > 0) {
    page = offset / limit + 1;
  }

  const minimalFetchOptions = { itemsPerPage: limit, page, servicePlatformId };

  if (search) {
    minimalFetchOptions.searchIdentity = search;
  }

  return fetch(
    `${advertiserApiUrl}?${queryParams({
      ...minimalFetchOptions,
      ...options,
    })}`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );
};

export const searchAdvertisersWithPlatformApi = async (search, options = {}) => {
  const { userId, ...restOptions } = options;
  const minimalOptions = { userId, servicePlatformId, limit: 20, offset: 0, itemsPerPage: 20, ...restOptions };

  if (search) {
    minimalOptions.searchIdentity = search;
  }

  return queryGraphqlWithPagination(
    {
      name: 'getAdvertisers',
      query: SEARCH_ADVERTISER_WITH_PLATFORM,
    },
    { options: minimalOptions }
  );
};

export const getAdvertiserUsersApi = async (advertiserId, options = {}) => {
  const minimalOptions = { limit: 20, offset: 0, itemsPerPage: 20, ...options };

  const variables = {
    advertiserId,
  };

  return queryGraphqlWithPagination(
    {
      name: 'getAdvertiserUsers',
      query: GET_ADVERTISER_USERS,
      variables,
    },
    { options: minimalOptions, variables }
  );
};

export const getAdvertiserApi = (advertiserId, userId) =>
  client.query({
    name: 'getAdvertiser',
    query: GET_ADVERTISER,
    variables: { advertiserId, userId },
  });

export const getAdvertisersApi = filtersOption =>
  fetch(`${advertiserApiUrl}?${queryParams(filtersOption)}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getAdvertiserWithBillingApi = advertiserId =>
  fetch(`${advertiserApiUrl}/${advertiserId}/billing`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const putAdvertiserApi = (advertiserId, body) =>
  fetch(`${advertiserApiUrl}/${advertiserId}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const putAdvertiserBlindApi = (advertiserId, body) =>
  fetch(`${advertiserApiUrl}/${advertiserId}/blind`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const getTotalUserAdvertisersApi = search => {
  const filtersOption = {
    itemsPerPage: 1,
    servicePlatformId,
  };

  let url = `${advertiserApiUrl}?${queryParams(filtersOption)}`;

  if (search) {
    url = `${advertiserApiUrl}?${queryParams({
      ...filtersOption,
      searchIdentity: search,
    })}`;
  }

  return fetch(url, {
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
};

export const getTotalAdvertiserUsersApi = advertiserId => {
  const filtersOption = {
    itemsPerPage: 1,
  };

  return fetch(`${userHasAdvertiserUrl}?${queryParams(filtersOption)}&serviceAdvertiserId=${advertiserId}`, {
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
};

export const getCampaignsApi = id =>
  fetch(`${process.env.REACT_APP_ADVERTISER_API_URL}/campaigns?advertisers.id=${id}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getAdvertiserAssignableResourcesByRolesApi = (advertiserId, userId) =>
  client.query({
    name: 'getAdvertiserAssignableResourcesByRolesApi',
    query: GET_ADVERTISER_ASSIGNABLE_RESOURCES_BY_ROLES,
    variables: { advertiserId, userId },
  });

export const searchAdvertisersUsersApi = options => {
  const { search, ...resetOptions } = options;
  const fetchOptions = { ...resetOptions };

  if (search) {
    fetchOptions.value = search;
  }

  return fetchWithPagination(`${process.env.REACT_APP_PROXY_USER_API}/api/search_user_on_advertisers`, {
    options: fetchOptions,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
};

export const getCampaignDetailsApi = campaignLegacyId =>
  fetch(`${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignLegacyId}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getCampaignPromosApi = ({ campaignLegacyId, options }) =>
  fetch(`${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignLegacyId}/promos?${queryParams(options)}`, {
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export default {
  postAdvertiserResources,
  searchAdvertisersApi,
  searchAdvertisersWithPlatformApi,
  getAdvertiserApi,
  getAdvertisersApi,
  getTotalUserAdvertisersApi,
  getAdvertiserWithBillingApi,
  putAdvertiserApi,
  putAdvertiserBlindApi,
  getAdvertiserUsersApi,
  getTotalAdvertiserUsersApi,
  getCampaignsApi,
  getAdvertiserAssignableResourcesByRolesApi,
  searchAdvertisersUsersApi,
  getCampaignDetailsApi,
  getCampaignPromosApi,
};
