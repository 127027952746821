import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { assets, ErrorBoundary } from 'timeone-components';

import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { AdvertisersContext, AdvertiserSelectContext } from '../Providers';
import { Loader } from '../components';
import { NoTwaMessageContainer } from './styledComponents';
import { isFeatureEnabled, captureException } from '../utils';
import CONSTANTS from '../constants';

const { HomeIcon } = assets.icons;
const PATHS = {
  default: '/',
  analytics_consent: '/consent/visits',
  analytics_conversion_consent: '/consent/conversions',
  accounts: '/accounts',
  campaign: '/campaign',
  dashboard: '/dashboards',
  dashboard_monthly: '/dashboards/monthly',
  dashboard_weekly: '/dashboards/weekly',
  emailing: '/emailing',
  highlights: '/highlights',
  highlights_management: '/highlights/management',
  highlights_propositions: '/highlights/propositions',
  invoicing: '/invoicing',
  statistics: '/statistics',
  statistics_emailing: '/statistics/emailing',
  statistics_by_campaign: '/statistics/by-campaign',
  publishers: '/publishers',
  publishers_pending: '/publishers/pending',
  actions: '/actions', // This path is important to keep like this because it is used in email send by the api
  pending_actions: '/actions/pending', // This path is important to keep like this because it is used in email send by the api
  cashback: '/actions/cashbacks',
  cashback_pending: '/actions/cashbacks/pending',
  users: '/users',
};
const PERMISSIONS_PAGE_KEY = 'frontend:advertiser:page:';
const PROFILES = {
  all: 'all',
  blind: 'blind',
};

function MaintenancePage() {
  return (
    <ErrorPage type="Maintenance" message="we are actively working to make this page available as soon as possible" />
  );
}

function FetchingProfile() {
  const { advertisers, campaigns } = useContext(AdvertisersContext);

  return advertisers && campaigns ? (
    <Loader>Fetching profile</Loader>
  ) : (
    <ErrorPage
      type="Sorry"
      message={`You are not linked to any ${!advertisers ? 'advertiser' : 'campaign'} anymore.`}
    />
  );
}

function PageErrorBoundary({ children }) {
  const location = useLocation();

  return (
    <ErrorBoundary
      onError={err => {
        console.log(`page ${location.pathname} error:`, err);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
function NoTwaMessage({ selectedAdvertiser }) {
  return (
    <NoTwaMessageContainer>
      No web analytics account has been set-up for {selectedAdvertiser?.name} (#{selectedAdvertiser?.legacyId}). Be part
      of the game by contacting <a href="mailto:trafic@timeonegroup.com">trafic@timeonegroup.com</a> !
    </NoTwaMessageContainer>
  );
}

NoTwaMessage.propTypes = {
  selectedAdvertiser: PropTypes.shape({
    name: PropTypes.string,
    legacyId: PropTypes.number,
  }).isRequired,
};

function NoAm() {
  return (
    <NoTwaMessageContainer>
      This campaign is not linked to any account manager. Please <Link to="/contact">contact us</Link> to change this
      status.
    </NoTwaMessageContainer>
  );
}
export const withAdvertiserSelect = function withAdvertiserSelect(ComposedComponent) {
  return function SetAdvertiserSelect(props) {
    const { selectedAdvertiser } = useContext(AdvertisersContext);
    const { setDisabledInputSelect } = useContext(AdvertiserSelectContext);

    useEffect(() => {
      if (selectedAdvertiser.id) {
        setDisabledInputSelect(false);
      }
    }, [selectedAdvertiser.id]);

    return selectedAdvertiser.id ? <ComposedComponent {...props} /> : <Loader>Fetching advertiser</Loader>;
  };
};

export const withCampaign = function withCampaign(ComposedComponent) {
  return function CheckCampaigns(props) {
    const { campaigns } = useContext(AdvertisersContext);

    if (Array.isArray(campaigns) && campaigns.length > 0) {
      return <ComposedComponent {...props} />;
    }

    if (!campaigns) {
      return <ErrorPage type="Sorry" message="There is no active campaign for the selected advertiser" />;
    }

    return <Loader>Fetching campaigns</Loader>;
  };
};

const withoutInputSelectAdvertisers = function withoutInputSelectAdvertisers(ComposedComponent) {
  return function HideInputSelectAdvertisers(props) {
    const { disabledInputSelect, setDisabledInputSelect } = useContext(AdvertiserSelectContext);

    useEffect(() => {
      if (!disabledInputSelect) {
        setDisabledInputSelect(true);
      }
    }, []);

    return <ComposedComponent {...props} />;
  };
};

const withAm = function withAm(ComposedComponent) {
  return function CheckAm(props) {
    const { selectedCampaign } = useContext(AdvertisersContext);

    if (!selectedCampaign?.serviceUserAccountManagerId) {
      return <ErrorPage type="Access denied." message={<NoAm />} />;
    }

    return <ComposedComponent {...props} />;
  };
};

const defaultComponentDecorator = module => withAdvertiserSelect(withCampaign(module));
const simpleComponentDecorator = module => withoutInputSelectAdvertisers(module);
// eslint-disable-next-line no-unused-vars
const amComponentDecorator = module => withAdvertiserSelect(withCampaign(withAm(module)));
const defaultRouteConfig = {
  exact: true,
  ComponentDecorator: defaultComponentDecorator,
};

const defaultProfiles = { profiles: [PROFILES.all] };
const allRoutes = [
  {
    id: 'appCrash',
    route: { ...defaultRouteConfig, path: PATHS.default, componentPath: 'pages/ErrorPage/500' },
  },
  {
    id: 'dashboard',
    route: { ...defaultRouteConfig, path: PATHS.default, componentPath: 'pages/Dashboard/DashboardGraph' },
    entry: { name: <HomeIcon height={40} width={40} title="Home" />, to: PATHS.default, exact: true },
    ...defaultProfiles,
  },
  {
    id: 'dashboard_blind',
    route: { ...defaultRouteConfig, path: PATHS.default, componentPath: 'pages/Dashboard/blind/DashboardGraph' },
    entry: { name: <HomeIcon height={40} width={40} title="Home" />, to: PATHS.default, exact: true },
    profiles: [PROFILES.blind],
  },
  {
    id: 'dashboard_monthly|dashboard_monthly_blind|dashboard_weekly|dashboard_weekly_blind',
    route: {
      ...defaultRouteConfig,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'dashboard_monthly',
          path: PATHS.dashboard_monthly,
          componentPath: 'pages/Dashboard/DashboardMonthlyGraph',
        },
        {
          ...defaultRouteConfig,
          id: 'dashboard_monthly_blind',
          path: PATHS.dashboard_monthly,
          componentPath: 'pages/Dashboard/blind/DashboardMonthlyGraph',
        },
        {
          ...defaultRouteConfig,
          id: 'dashboard_weekly',
          path: PATHS.dashboard_weekly,
          componentPath: 'pages/Dashboard/DashboardWeeklyGraph',
        },
        {
          ...defaultRouteConfig,
          id: 'dashboard_weekly_blind',
          path: PATHS.dashboard_weekly,
          componentPath: 'pages/Dashboard/blind/DashboardWeeklyGraph',
        },
      ],
    },
    entry: {
      name: 'Dashboard',
      to: PATHS.dashboard,
      onBoardingId: 'menu-dashboards',
      subEntries: [
        {
          id: 'dashboard_monthly',
          name: 'Monthly data',
          to: PATHS.dashboard_monthly,
          exact: true,
        },
        {
          id: 'dashboard_monthly_blind',
          name: 'Monthly data',
          to: PATHS.dashboard_monthly,
          exact: true,
        },
        {
          id: 'dashboard_weekly',
          name: 'Weekly data',
          to: PATHS.dashboard_weekly,
          exact: true,
        },
        {
          id: 'dashboard_weekly_blind',
          name: 'Weekly data',
          to: PATHS.dashboard_weekly,
          exact: true,
        },
      ],
    },
    ...defaultProfiles,
  },
  {
    id: 'statistics',
    route: {
      ...defaultRouteConfig,
      path: PATHS.statistics,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'statistics',
          path: PATHS.statistics,
          componentPath: 'pages/Dashboard/Statistics',
          exact: true,
        },
        {
          ...defaultRouteConfig,
          id: 'statistics_by_campaign',
          path: PATHS.statistics_by_campaign,
          componentPath: 'pages/Dashboard/StatisticsByCampaign',
          featureFlagKey: CONSTANTS.featureFlagKeys.page.statisticsByCampaign,
          exact: true,
        },
        {
          ...defaultRouteConfig,
          id: 'statistics_emailing',
          path: PATHS.statistics_emailing,
          exact: true,
          componentPath: 'pages/Dashboard/StatisticsEmailing',
        },
      ],
    },
    entry: {
      name: 'Statistics',
      to: PATHS.statistics,
      onBoardingId: 'menu-statistics',
      subEntries: [
        {
          id: 'statistics',
          name: 'Global',
          to: PATHS.statistics,
          exact: true,
        },
        {
          id: 'statistics_by_campaign',
          name: 'By campaign',
          to: PATHS.statistics_by_campaign,
          exact: true,
          featureFlagKey: CONSTANTS.featureFlagKeys.page.statisticsByCampaign,
        },
        {
          id: 'statistics_emailing',
          name: 'Emailing',
          to: PATHS.statistics_emailing,
          exact: true,
        },
      ],
    },
    ...defaultProfiles,
  },
  {
    id: 'statistics_blind',
    route: {
      ...defaultRouteConfig,
      path: PATHS.statistics,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'statistics_blind',
          path: PATHS.statistics,
          componentPath: 'pages/Dashboard/blind/Statistics',
          exact: true,
        },
        {
          ...defaultRouteConfig,
          id: 'statistics_by_campaign_blind',
          path: PATHS.statistics_by_campaign,
          componentPath: 'pages/Dashboard/blind/StatisticsByCampaign',
          exact: true,
          featureFlagKey: CONSTANTS.featureFlagKeys.page.statisticsByCampaign,
        },
        {
          ...defaultRouteConfig,
          id: 'statistics_emailing_blind',
          path: PATHS.statistics_emailing,
          componentPath: 'pages/Dashboard/blind/StatisticsEmailing',
          exact: true,
        },
      ],
    },
    entry: {
      name: 'Statistics',
      to: PATHS.statistics,
      onBoardingId: 'menu-statistics',
      subEntries: [
        {
          id: 'statistics_blind',
          name: 'Global',
          to: PATHS.statistics,
          exact: true,
        },
        {
          id: 'statistics_by_campaign_blind',
          name: 'By campaign',
          to: PATHS.statistics_by_campaign,
          exact: true,
          featureFlagKey: CONSTANTS.featureFlagKeys.page.statisticsByCampaign,
        },
        {
          id: 'statistics_emailing_blind',
          name: 'Emailing',
          to: PATHS.statistics_emailing,
          exact: true,
        },
      ],
    },
    profiles: [PROFILES.blind],
  },
  {
    id: 'analytics_consent',
    route: {
      ...defaultRouteConfig,
      path: PATHS.analytics_consent,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'analytics_consent',
          path: PATHS.analytics_consent,
          componentPath: 'pages/Consents/ByVisits',
          exact: true,
        },
        {
          ...defaultRouteConfig,
          id: 'analytics_consent',
          path: PATHS.analytics_conversion_consent,
          componentPath: 'pages/Consents/ByConversions',
          exact: true,
        },
      ],
    },
    entry: {
      name: 'Consent',
      to: PATHS.analytics_consent,
      onBoardingId: 'menu-analytics-consent',
      subEntries: [
        {
          id: 'analytics_consent',
          name: 'By Visits',
          to: PATHS.analytics_consent,
          exact: true,
        },
        {
          id: 'analytics_consent',
          name: 'By Conversions',
          to: PATHS.analytics_conversion_consent,
          exact: true,
        },
      ],
    },
    ...defaultProfiles,
  },
  {
    id: 'myAccount',
    route: {
      ...defaultRouteConfig,
      path: PATHS.accounts,
      componentPath: 'pages/Accounts',
      ComponentDecorator: simpleComponentDecorator,
    },
    ...defaultProfiles,
  },
  {
    id: 'publishers',
    route: {
      path: PATHS.publishers,
      exact: true,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'publishers',
          path: PATHS.publishers,
          componentPath: 'pages/Publishers/PublishersList',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
        {
          ...defaultRouteConfig,
          id: 'publishers_pending',
          path: PATHS.publishers_pending,
          componentPath: 'pages/Publishers/PendingPublishersList',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
      ],
    },
    entry: {
      name: 'Publishers',
      to: PATHS.publishers,
      subEntries: [
        {
          id: 'publishers_pending',
          name: 'Pending publishers',
          to: PATHS.publishers_pending,
          exact: true,
        },
        {
          id: 'publishers',
          name: 'My publishers',
          to: PATHS.publishers,
          exact: true,
        },
      ],
      onBoardingId: 'menu-publishers-list',
    },
    ...defaultProfiles,
  },
  {
    id: 'actions',
    route: {
      path: PATHS.actions,
      exact: true,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'actions',
          path: PATHS.actions,
          componentPath: 'pages/Actions/Actions',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
        {
          ...defaultRouteConfig,
          id: 'pending_actions',
          path: PATHS.pending_actions,
          componentPath: 'pages/Actions/PendingActions',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
        {
          ...defaultRouteConfig,
          id: 'cashback',
          path: PATHS.cashback,
          componentPath: 'pages/Cashback/Cashback',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
        {
          ...defaultRouteConfig,
          id: 'cashback_pending',
          path: PATHS.cashback_pending,
          componentPath: 'pages/Cashback/PendingCashback',
          exact: true,
          // ComponentDecorator: amComponentDecorator,
        },
      ],
    },
    entry: {
      name: 'Actions',
      to: PATHS.actions,
      subEntries: [
        {
          id: 'pending_actions',
          name: 'Pending actions',
          to: PATHS.pending_actions,
          exact: true,
        },
        {
          id: 'actions',
          name: 'Actions history',
          to: PATHS.actions,
          exact: true,
        },
        {
          id: 'cashback_pending',
          name: 'Pending cashback claims',
          to: PATHS.cashback_pending,
          exact: true,
        },
        {
          id: 'cashback',
          name: 'Cashback claims history',
          to: PATHS.cashback,
          exact: true,
        },
      ],
    },
    ...defaultProfiles,
  },
  {
    id: 'emailing',
    route: {
      ...defaultRouteConfig,
      path: PATHS.emailing,
      componentPath: 'pages/Emailing',
    },
    entry: { name: 'Emailing', to: PATHS.emailing },
    ...defaultProfiles,
  },
  {
    id: 'highlights',
    route: {
      ...defaultProfiles,
      path: PATHS.highlights,
      exact: true,
      subRoutes: [
        {
          ...defaultRouteConfig,
          id: 'highlights',
          path: PATHS.highlights_management,
          componentPath: 'pages/Highlights/ManagementHighlights',
          componentDecorator: defaultComponentDecorator,
          exact: true,
        },
        {
          ...defaultRouteConfig,
          id: 'highlights_pending',
          path: PATHS.highlights_propositions,
          componentPath: 'pages/Highlights/PropositionsHighlights',
          componentDecorator: defaultComponentDecorator,
          exact: true,
        },
      ],
    },
    entry: {
      name: 'Highlights',
      to: PATHS.highlights,
      subEntries: [
        {
          id: 'highlights_pending',
          name: 'Highlights validation',
          to: PATHS.highlights_propositions,
          exact: true,
        },
        {
          id: 'highlights',
          name: 'Highlights management',
          to: PATHS.highlights_management,
          exact: true,
        },
      ],
    },
    ...defaultProfiles,
  },
  {
    id: 'campaign',
    route: { ...defaultRouteConfig, path: PATHS.campaign, componentPath: 'pages/Campaign' },
    entry: { name: 'Campaign', to: PATHS.campaign },
    ...defaultProfiles,
  },
  {
    id: 'invoicing',
    route: {
      ...defaultRouteConfig,
      path: PATHS.invoicing,
      componentPath: 'pages/Invoicing',
    },
    entry: { name: 'Invoicing', to: PATHS.invoicing },
    ...defaultProfiles,
  },
  {
    id: 'users',
    isGlobal: true,
    route: {
      ...defaultRouteConfig,
      path: PATHS.users,
      componentPath: 'pages/Users',
      ComponentDecorator: simpleComponentDecorator,
    },
    ...defaultProfiles,
  },
];

const pendingRoutes = [{ path: '/', component: FetchingProfile }];
const pendingEntries = [];

function isFeatureFlagEnabled(featureFlagKey) {
  return featureFlagKey ? isFeatureEnabled(featureFlagKey) : true;
}

export function getRouteIdByPermissions(permissions = []) {
  return permissions
    .filter(permission => permission.indexOf(PERMISSIONS_PAGE_KEY) === 0)
    .map(permission => permission.slice(PERMISSIONS_PAGE_KEY.length));
}

function getElement({ componentPath, ComponentDecorator }) {
  const Element = React.lazy(() => import(`../${componentPath}`));
  const Component = ComponentDecorator ? ComponentDecorator(Element) : Element;

  return function Page() {
    const { pathname } = useLocation();

    const maintenanceKey = `front-advertiser-maintenance${pathname.replaceAll('/', '-')}`;

    if (isFeatureFlagEnabled(maintenanceKey)) {
      return <MaintenancePage />;
    }

    return (
      <PageErrorBoundary>
        <React.Suspense fallback={<Loader />}>
          <Component />
        </React.Suspense>
      </PageErrorBoundary>
    );
  };
}

export function getRoutes(permissionRouteIds, globalPermissionRouteIds) {
  function makeRouteLoadable({ componentPath, ComponentDecorator, subRoutes, ...props }) {
    return {
      ...props,
      component: getElement({ componentPath, ComponentDecorator }),

      subRoutes:
        subRoutes &&
        subRoutes
          .filter(({ id, featureFlagKey }) => permissionRouteIds.includes(id) && isFeatureFlagEnabled(featureFlagKey))
          .map(makeRouteLoadable),
    };
  }

  return allRoutes
    .filter(({ id, featureFlagKey, isGlobal }) => {
      const permissions = isGlobal ? globalPermissionRouteIds : permissionRouteIds;

      return (
        (permissions.includes(id) || permissionRouteIds.some(routeId => id.split('|').includes(routeId))) &&
        isFeatureFlagEnabled(featureFlagKey)
      );
    })
    .map(({ route }) => makeRouteLoadable(route));
}

export function getMenuEntries(permissionRouteIds, menuEntriesToOmit = []) {
  const allowEntries = allRoutes
    .filter(
      ({ id, featureFlagKey, entry }) =>
        entry &&
        (permissionRouteIds.includes(id) || permissionRouteIds.some(routeId => id.split('|').includes(routeId))) &&
        (!menuEntriesToOmit.includes(id) || !menuEntriesToOmit.some(routeId => id.split('|').includes(routeId))) &&
        isFeatureFlagEnabled(featureFlagKey)
    )
    .map(({ entry }) =>
      entry?.subEntries
        ? {
            ...entry,
            subEntries: entry.subEntries.filter(
              ({ id, featureFlagKey }) => permissionRouteIds.includes(id) && isFeatureFlagEnabled(featureFlagKey)
            ),
          }
        : entry
    );

  const allowExtraEntries = allRoutes
    .filter(({ id, extraEntry }) => extraEntry && permissionRouteIds.includes(id) && !menuEntriesToOmit.includes(id))
    .map(({ extraEntry }) => extraEntry);

  return { allowEntries, allowExtraEntries };
}

export default function useRouter({ permissions, menuEntriesToOmit, globalPermissions }) {
  const [routes, setRoutes] = useState(pendingRoutes);
  const [entries, setEntries] = useState(pendingEntries);
  const [extraEntries, setExtraEntries] = useState();

  useEffect(() => {
    if (permissions) {
      try {
        const permissionRouteIds = getRouteIdByPermissions(permissions);
        const globalPermissionRouteIds = getRouteIdByPermissions(globalPermissions);

        const allowRoutes = getRoutes(permissionRouteIds, globalPermissionRouteIds);
        const { allowEntries, allowExtraEntries } = getMenuEntries(permissionRouteIds, menuEntriesToOmit);

        setRoutes(allowRoutes);
        setEntries(allowEntries);
        setExtraEntries(allowExtraEntries);
      } catch (error) {
        captureException({ error, type: error.type || 'component', component: 'useRouter' });
        const errorRoute = getRoutes(['appCrash']);

        setRoutes(errorRoute);
      }
    }
  }, [permissions, globalPermissions]);

  return { routes, entries, extraEntries };
}
