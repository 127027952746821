import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usersApi, ApplicationLayoutContext, assets, Avatar } from 'timeone-components';
import overwriteTheme from '../../theme';
import { AdvertisersContext, AdvertiserSelectContext } from '../../Providers';
import { contactService } from '../../services';
import { Loader, SelectCampaign } from '../../components';
import { captureException } from '../../utils';
import {
  MainContainer,
  ContactContainer,
  ContactInner,
  ContactText,
  ContactTitle,
  ContactSubTitle,
  ContactCardsWrapper,
  ContactCardContainer,
  ContactCardImageContainer,
  ContactCardContent,
  ContactCardContentTitle,
  ContactCardEmailContainer,
  getAMAvatarCss,
} from './styledComponents';

const { advertiserPrimaryLogo } = assets.logos;

function ContactCard({ title, job, email, tel, image }) {
  const { media } = useContext(ApplicationLayoutContext);

  return (
    <ContactCardContainer query={media.query}>
      <ContactCardImageContainer query={media.query}>
        {image || <img src={advertiserPrimaryLogo} alt="" role="presentation" />}
      </ContactCardImageContainer>
      <ContactCardContent>
        <ContactCardContentTitle>{title}</ContactCardContentTitle>
        {job && <p>{job}</p>}
        {email && (
          <ContactCardEmailContainer>
            <a href={`mailto:${email}`}>{email}</a>
          </ContactCardEmailContainer>
        )}
        {tel && (
          <p>
            <a href={`tel:${tel}`}>{tel}</a>
          </p>
        )}
      </ContactCardContent>
    </ContactCardContainer>
  );
}

ContactCard.propTypes = {
  title: PropTypes.string,
  job: PropTypes.string,
  email: PropTypes.string,
  tel: PropTypes.string,
  image: PropTypes.node,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Contact() {
  const { media } = useContext(ApplicationLayoutContext);
  const { selectedAdvertiser, selectedCampaign } = useContext(AdvertisersContext);
  const { setDisabledInputSelect } = useContext(AdvertiserSelectContext);
  const [accountManager, setAccountManager] = useState();
  const [platformContacts, setPlatformContacts] = useState();
  const [campaignContacts, setCampaignContacts] = useState();

  const { useGetUsersApi } = usersApi;
  const { dataUsers, getUsers } = useGetUsersApi(process.env.REACT_APP_USER_RESOURCE_API_URL);

  async function getPlatformContacts() {
    try {
      const platformCts = await contactService.getPlatformContacts('page-contact');

      setPlatformContacts(platformCts);
    } catch (error) {
      captureException({ error, type: error.type || 'fecthData', component: 'Contact' });
    }
  }

  async function getCampaignContacts() {
    try {
      const campaignCts = await contactService.getCampaignTeams(selectedAdvertiser.id, selectedCampaign.id);

      setCampaignContacts(campaignCts);
    } catch (error) {
      captureException({ error, type: error.type || 'fecthData', component: 'Contact' });
    }
  }

  function shouldDisplayContact() {
    return selectedCampaign && selectedCampaign.id && selectedAdvertiser && selectedAdvertiser.id;
  }

  useEffect(() => {
    if (selectedAdvertiser.id) {
      setDisabledInputSelect(false);
    }
  }, [selectedAdvertiser.id]);

  useEffect(() => {
    if (dataUsers) {
      setAccountManager(dataUsers);
    }
  }, [dataUsers]);

  useEffect(() => {
    getPlatformContacts();
  }, []);

  useEffect(() => {
    if (selectedCampaign.serviceUserAccountManagerId) {
      getUsers(selectedCampaign.serviceUserAccountManagerId);
      getCampaignContacts();
    }
  }, [selectedCampaign]);

  return shouldDisplayContact() ? (
    <MainContainer>
      <SelectCampaign />
      <ContactContainer>
        <ContactInner query={media.query}>
          <ContactText query={media.query}>
            <ContactTitle>Contact us !</ContactTitle>
            <ContactSubTitle>A question, a problem or some suggestions ?</ContactSubTitle>
          </ContactText>

          <ContactCardsWrapper query={media.query}>
            {accountManager && (
              <ContactCard
                query={media.query}
                title={`${accountManager.firstName} ${accountManager.lastName}`}
                job="Account manager"
                email={accountManager.email}
                tel={accountManager.phone1 || accountManager.phone2}
                image={<Avatar {...accountManager} customCss={getAMAvatarCss(media)} />}
              />
            )}

            {platformContacts &&
              platformContacts.map(({ id, contactName, mail, phoneNumber }) => (
                <ContactCard key={id} title={contactName} email={mail} tel={phoneNumber} />
              ))}

            {campaignContacts &&
              campaignContacts.map(({ id, name, mail, phoneNumber }) => (
                <ContactCard key={id} title={name} email={mail} tel={phoneNumber} />
              ))}
          </ContactCardsWrapper>
        </ContactInner>
      </ContactContainer>
    </MainContainer>
  ) : (
    <Loader>Fetching data</Loader>
  );
}

Contact.defaultProps = {
  theme: overwriteTheme,
};

Contact.propTypes = {
  theme: PropTypes.shape({}),
};
