/* eslint-disable sonarjs/no-duplicate-string */

import { getPlatformContactsApi, getCampaignTeamsApi } from './contact.api';

export const getPlatformContacts = async tagsLabel => {
  const response = await getPlatformContactsApi(tagsLabel);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }

  const result = await response.json();

  return result || 0;
};

export const getCampaignTeams = async (serviceAdvertiserId, serviceCampaignId) => {
  const response = await getCampaignTeamsApi(serviceAdvertiserId, serviceCampaignId);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }

  const result = await response.json();

  return result?.map?.(member => member.team) || 0;
};

export default {
  getPlatformContacts,
  getCampaignTeams,
};
