/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const NoDataContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.orange};
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }
`;
