import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';
import { format } from 'timeone-components';

const defaultLogErrors = console.error.bind(console);

export function formatDate(value) {
  return format.date({ value, locale: 'fr', set: 'L' });
}

export function formatTime(value) {
  return format.date({ value, locale: 'fr', set: 'LTS' });
}

export function formatDateTime(value) {
  return format.date({ value, locale: 'fr', set: 'L LTS' });
}

export function isProduction() {
  return process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'preproduction';
}

export function isSandbox() {
  return process.env.REACT_APP_ENV === 'development';
}

export function disableWarnProptypeInNavigator() {
  if (isSandbox()) {
    console.error = (...args) => {
      const [errormessage] = args;
      const suppressedErrors =
        typeof errormessage === 'string' &&
        (errormessage.includes('Warning: Failed prop type:') || errormessage.includes('Warning: Failed %s type:'));

      if (!suppressedErrors) {
        defaultLogErrors.apply(args);
      }
    };
  }
}

export function isPosthogActive() {
  return isProduction() && process.env.REACT_APP_POSTHOG_KEY && process.env.REACT_APP_POSTHOG_HOST;
}

export function isFeatureEnabled(key) {
  if (process.env.REACT_APP_ENV !== 'production') {
    return !key.includes('maintenance');
  }

  return isPosthogActive() && posthog.isFeatureEnabled(key);
}

export function isSentryActive() {
  return isProduction() && process.env.REACT_APP_SENTRY_DNS;
}

function shouldIgnoreError(error) {
  return error?.status === 401;
}

export function captureException({ error, type = 'default', component }) {
  if (!isSentryActive()) {
    console.error({ type, component }, error);
    return;
  }

  if (shouldIgnoreError) {
    return;
  }

  const scope = new Sentry.Scope();

  scope.setTag('tog.error.type', type);

  if (component) {
    scope.setTag('tgo.component', component);
  }

  if (error.type === 'http') {
    scope.setTag('tog.error.type', 'http');
    // scope.setContext('tog:http:request', error?.context?.request); For now, we are NOT able to keep the data consistent when multiple http calls are made simultaneously
    scope.setContext('tog:http:response', error?.context?.response);
  }

  Sentry.captureException(error, scope);
}
