import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getConfig } from 'timeone-components';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

import { userService } from '../services';
import { isPosthogActive, isSentryActive, captureException } from '../utils';

export const UserContext = React.createContext({});

function setPosthogUserIdentity({ id, email }) {
  if (isPosthogActive()) {
    posthog.identify(btoa(`advertiser:${id}`), { email, platform: 'advertiser.timeonegroup.com' });
  }
}

function setSentryUser({ id, email }) {
  if (isSentryActive()) {
    Sentry.setUser({ id, email, sharedId: btoa(`advertiser:${id}`) });
  }
}

function updatePosthogUser({ email }) {
  if (isPosthogActive()) {
    posthog.people.set({ email });
  }
}

function UserProvider({ children }) {
  const [user, setUser] = useState({});

  getConfig().registerHandler('user:update', updatedUser => {
    setUser(updatedUser);
    updatePosthogUser(updatedUser);
    setSentryUser(updatedUser);
  });

  const getUser = async () => {
    try {
      const fetchedUser = await userService.getMe();

      setUser(fetchedUser);
      setPosthogUserIdentity(fetchedUser);
      setSentryUser(fetchedUser);
    } catch (error) {
      captureException({ error, type: error.type || 'fecthData', component: 'UserProvider' });

      // throw new Error(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.PropTypes.node.isRequired,
};

export default UserProvider;
