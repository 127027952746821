import { token } from 'timeone-components';
import { fetchWithPagination } from '../utils';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

export const getCashbackPendingApi = ({ campaignId, options }) =>
  fetchWithPagination(
    `${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/cashbacks/pending`,
    {
      options,
      headers: {
        ...headersLd,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const getCashbackHistoryApi = ({ campaignId, options }) =>
  fetchWithPagination(
    `${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/cashbacks/history`,
    {
      options,
      headers: {
        ...headersLd,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const createExportCashbacksApi = ({ campaignId, body, type }) =>
  fetch(`${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/export_cashbacks/${type}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const getExportCashbacksApi = ({ campaignId, id }) =>
  fetch(`${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/export_cashbacks/${id}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getCashbacksFileApi = ({ campaignId, id }) =>
  fetch(
    `${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/export_cashbacks/${id}/download`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const uploadFileApi = (campaignId, body) =>
  fetch(`${process.env.REACT_APP_CASHBACK_API_URL}/performance/campaigns/${campaignId}/cashback_files`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body,
  });
