import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ApplicationLayout, IsAuthenticate, TimeOneConfig, getTimeOneRoutes, ToastContainer } from 'timeone-components';
import { Route, Switch, useLocation } from 'react-router-dom';
// import posthog from 'posthog-js';

import ErrorPage404 from '../pages/ErrorPage/404';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import Contact from '../pages/Contact';
import { UserContext, AdvertisersContext } from '../Providers';
import headerProps from './layoutData';
import useRouter from './useRouter';
import { userService } from '../services';
import theme from '../theme';
import localStorageService from '../services/localStorage';
// import { isPosthogActive } from '../utils';
import { Paragraph } from './styledComponents';
import { Notifications } from '../components';

function AppRouter({ routes }) {
  const location = useLocation();

  React.useEffect(() => {
    localStorageService.resetLists();

    // if (isPosthogActive()) {
    //   posthog.capture('$pageview');
    // }
  }, [location]);

  return routes ? (
    <Switch>
      {routes
        .map(route => {
          if (route?.subRoutes?.length > 0) {
            return route.subRoutes.map(subRoute => <Route {...subRoute} key={subRoute.path} />);
          }

          if (route.component) {
            return <Route {...route} key={route.path} />;
          }

          return null;
        })
        .filter(Boolean)}
      {getTimeOneRoutes()}
      <Route path="/contact" component={Contact} />
      <Route component={ErrorPage404} />
    </Switch>
  ) : null;
}

AppRouter.defaultProps = {
  routes: null,
};

AppRouter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string })),
};

function Message() {
  return (
    <div>
      <Paragraph>We are not able to load content. </Paragraph>
      <Paragraph>You may have an adblocker, please turn it off and refresh to make Advertiser app works.</Paragraph>
    </div>
  );
}
function TestAdBlock({ children }) {
  const [hasAdBlock, setHasAdBlock] = useState(false);

  useEffect(() => {
    fetch('/advertisement.js?advertiser=1').catch(() => {
      setHasAdBlock(true);
    });
  }, []);

  if (hasAdBlock) {
    return <ErrorPage type="Sorry" message={<Message />} />;
  }

  return children;
}

function Layout() {
  const user = useContext(UserContext);
  const { selectedCampaign } = useContext(AdvertisersContext);

  const { routes, entries, extraEntries } = useRouter({
    permissions: selectedCampaign?.permissions,
    // eslint-disable-next-line react/destructuring-assignment
    globalPermissions: user.permissions,
  });

  const footerEntries = [{ id: 'contact', name: 'Contact', to: '/contact' }];

  return (
    <ApplicationLayout
      className="App"
      headerProps={{ ...headerProps, entries, extraEntries }}
      footerEntries={footerEntries}
      user={user}
      onLogout={userService.logOutUser}
    >
      <TestAdBlock>
        <TimeOneConfig />
        <AppRouter routes={routes} />
        <Notifications />
      </TestAdBlock>
      <ToastContainer />
    </ApplicationLayout>
  );
}

const shouldAppUseLogin = !['CI', 'test'].includes(process.env.NODE_ENV);

function AppWithAuth() {
  return (
    <ThemeProvider theme={theme}>
      {shouldAppUseLogin ? <IsAuthenticate RenderComponent={Layout} /> : <Layout />}
    </ThemeProvider>
  );
}

export default AppWithAuth;
