import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { userService } from '../services';
import { captureException } from '../utils';

export const DashboardPeriodContext = React.createContext({});

export const PREFERENCE_TAG_KEYS = {
  dashboardPeriod: 'dashboardPeriod',
};

const PREFERENCE_TAG = {
  dashboardPeriod: 'advertiser:dashboard:selectedPeriod',
};

export const DATE_FORMAT = 'YYYY/MM/DD';

export function formatPeriod(period) {
  return {
    startDate: moment(period.startDate, DATE_FORMAT),
    endDate: moment(period.endDate, DATE_FORMAT),
    presetSlug: period.presetSlug,
    plainStartDate: period.startDate,
    plainEndDate: period.endDate,
  };
}

const defaultState = Object.keys(PREFERENCE_TAG).reduce((acc, key) => ({ ...acc, [key]: undefined }), {});

function DashboardPeriodProvider({ children }) {
  const [state, setState] = useState(defaultState);
  const [preferenceHash, setPreferenceHash] = useState(defaultState);

  async function getPeriodFromPreference(stateName, preferenceKey) {
    try {
      const [preference] = await userService.getUserPreference(preferenceKey);

      if (!preference?.value) {
        setPreferenceHash(prevState => ({ ...prevState, [stateName]: null }));

        return { [stateName]: undefined };
      }

      const period = JSON.parse(preference.value);

      setPreferenceHash(prevState => ({ ...prevState, [stateName]: preference.id }));

      return {
        [stateName]: formatPeriod(period),
      };
    } catch (e) {
      setPreferenceHash(prevState => ({ ...prevState, [stateName]: null }));
      captureException({ error: e, type: e.type || 'fecthData', component: 'DashboardPeriodProvider' });

      return { [stateName]: undefined };
    }
  }

  async function getInitialeData() {
    const promiseCollection = Object.entries(PREFERENCE_TAG).map(([key, value]) => getPeriodFromPreference(key, value));
    const resultsCollection = await Promise.all(promiseCollection);
    const results = resultsCollection.reduce((acc, result) => ({ ...acc, ...result }));

    setState(prevState => ({
      ...prevState,
      ...results,
    }));
  }

  useEffect(() => {
    getInitialeData();
  }, []);

  const addDashboardPeriodToPreference = useCallback(
    (preferenceTagKey = PREFERENCE_TAG_KEYS.dashboardPeriod) =>
      async period => {
        const preferenceTag = PREFERENCE_TAG[preferenceTagKey];
        const payload = {
          startDate: period.startDate.format(DATE_FORMAT),
          endDate: period.endDate.format(DATE_FORMAT),
          presetSlug: period.presetSlug,
        };

        if (preferenceTag && preferenceHash[preferenceTagKey]) {
          userService.updateUserPreference(preferenceHash[preferenceTagKey], preferenceTag, payload);
          setState(prevState => ({ ...prevState, [preferenceTagKey]: formatPeriod(payload) }));
        } else if (preferenceTag) {
          const { id } = await userService.createUserPreference(preferenceTag, payload);

          setPreferenceHash(prevState => ({ ...prevState, [preferenceTagKey]: id }));
          setState(prevState => ({ ...prevState, [preferenceTagKey]: formatPeriod(payload) }));
        }
      },
    [preferenceHash]
  );

  const context = useMemo(
    () => ({
      ...state,
      addDashboardPeriodToPreference,
    }),
    [state, addDashboardPeriodToPreference]
  );

  return <DashboardPeriodContext.Provider value={context}>{children}</DashboardPeriodContext.Provider>;
}

DashboardPeriodProvider.propTypes = {
  children: PropTypes.PropTypes.node.isRequired,
};

export default DashboardPeriodProvider;
