export const DISPLAY_TYPE = {
  blocked: 'blocking',
};

export const TEMPLATES = {
  1: 'Modal',
};

export const MESSAGE_KEYS = {
  1: 'InvoicingNotPaid',
};
