import { token } from 'timeone-components';
import { stitchingClient, queryParams } from '../utils';
import {
  GET_MY_PUBLISHERS,
  GET_PENDING_PUBLISHERS,
  GET_PUBLISHER_DETAILS,
  GET_PUBLISHERS_DETAILS,
} from './publisher.query';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getPublishersApi = ({ campaignId }) =>
  campaignId
    ? stitchingClient.query({
        name: 'getPublishers',
        query: GET_MY_PUBLISHERS,
        variables: { campaignId },
      })
    : null;

export const getPendingPublishersApi = ({ campaignId }) =>
  campaignId
    ? stitchingClient.query({
        name: 'getPendingPublishers',
        query: GET_PENDING_PUBLISHERS,
        variables: { campaignId },
      })
    : null;

export const getDetailPublisherApi = ({ campaignId, providerId }) =>
  campaignId && providerId
    ? stitchingClient.query({
        name: 'getDetailPublisher',
        query: GET_PUBLISHER_DETAILS,
        variables: { campaignId, providerId },
      })
    : null;

export const getPublishersDetailsApi = ({ idList }) =>
  idList
    ? stitchingClient.query({
        name: 'getPublishersDetails',
        query: GET_PUBLISHERS_DETAILS,
        variables: { idList },
      })
    : null;

export const getCampaignGroupsCommissionApi = ({ advertiserId, campaignId }) =>
  fetch(
    `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}/advertisers/${advertiserId}/campaigns/${campaignId}/group_of_commissions`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const getRefusedReasonsApi = ({ advertiserId, campaignId }) =>
  fetch(
    `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}/advertisers/${advertiserId}/campaigns/${campaignId}/providers/subscription/reason`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const postProviderSubscriptionsApi = ({ advertiserId, campaignId, body }) =>
  fetch(
    `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}/advertisers/${advertiserId}/campaigns/${campaignId}/providers/subscription`,
    {
      method: 'POST',

      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
      body: JSON.stringify(body),
    }
  );

export const getDistributionMethodsApi = options =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/distribution_methods?${queryParams(options)}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export default {
  getPublishersApi,
  getPendingPublishersApi,
  getCampaignGroupsCommissionApi,
  postProviderSubscriptionsApi,
  getDetailPublisherApi,
  getRefusedReasonsApi,
  getPublishersDetailsApi,
  getDistributionMethodsApi,
};
