import { getSelectedAdvertiser, setSelectedAdvertiser } from './selectedAdvertiser';
import { resetLists, getListPage, setListPage, getListSearch, setListSearch } from './lists';

export default {
  getSelectedAdvertiser,
  setSelectedAdvertiser,
  resetLists,
  getListPage,
  setListPage,
  getListSearch,
  setListSearch,
};
