import React from 'react';
import { assets } from 'timeone-components';

import { NoDataContainer } from './styledComponents';
import CONSTANTS from '../../constants';

const { WarningIcon } = assets.icons;

export default function NoData() {
  return (
    <NoDataContainer>
      <WarningIcon isInteractive={false} /> {CONSTANTS.errors.noData}
    </NoDataContainer>
  );
}
