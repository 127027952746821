import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const LogoStyled = styled.img`
  height: 2.2rem;
  width: 2.2rem;
`;

export const NavLinkStyled = styled(NavLink)`
  &.active {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

export const NoTwaMessageContainer = styled.span`
  a {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const Paragraph = styled.p`
  text-align: center;
`;
