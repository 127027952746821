import {
  postAdvertiserResources,
  searchAdvertisersApi,
  getAdvertiserApi,
  getAdvertisersApi,
  getTotalUserAdvertisersApi,
  searchAdvertisersWithPlatformApi,
  getAdvertiserWithBillingApi,
  putAdvertiserApi,
  putAdvertiserBlindApi,
  getAdvertiserUsersApi,
  getTotalAdvertiserUsersApi,
  getCampaignsApi,
  getAdvertiserAssignableResourcesByRolesApi,
  searchAdvertisersUsersApi,
  getCampaignDetailsApi,
  getCampaignPromosApi,
} from './advertiser.api';
import { getMeUid, handleFetchResponse } from '../utils';

const HYDRA = {
  member: 'hydra:member',
  totalItems: 'hydra:totalItems',
};

export const connectAdvertiserResources = async resources => {
  const userId = getMeUid();
  const response = await postAdvertiserResources(userId, resources);

  return handleFetchResponse(response);
};

export const searchAdvertisers = async (search, options) => {
  const response = await searchAdvertisersApi(search, options);

  return handleFetchResponse(response);
};

export const getAdvertiserUsers = async (search, options) => {
  const response = await getAdvertiserUsersApi(search, options);

  if (response && Array.isArray(response)) {
    return response;
  }

  if (response?.data?.getAdvertiserUsers) {
    return response.data.getAdvertiserUsers;
  }

  throw new Error('Error while fetching advertiser users');
};

export const searchAdvertisersWithPlatform = async (search, options) => {
  const response = await searchAdvertisersWithPlatformApi(search, options);

  if (response && Array.isArray(response)) {
    return response;
  }

  if (response?.data?.getAdvertisers) {
    return response.data.getAdvertisers;
  }

  throw new Error('Error while fetching advertisers with platform');
};

export const searchAdvertisersWithPermissions = async (search, options) => {
  const userId = getMeUid();
  const response = await searchAdvertisersWithPlatformApi(search, { ...options, userId });

  if (response && Array.isArray(response)) {
    return response;
  }

  if (response?.data?.getAdvertisers) {
    return response.data.getAdvertisers;
  }

  throw new Error('Error while fetching advertisers');
};

export const getAdvertiser = async advertiserId => {
  const userId = getMeUid();
  const response = await getAdvertiserApi(advertiserId, userId);

  if (response?.data?.getAdvertiser) {
    return response.data.getAdvertiser;
  }

  throw new Error(`Error while fetching advertiser ${advertiserId}`);
};

export const getAdvertisers = async (search, options) => {
  const response = await getAdvertisersApi(search, options);

  return handleFetchResponse(response);
};

export const getAdvertiserWithBilling = async (advertiserId = {}) => {
  const userId = getMeUid();
  const response = await getAdvertiserWithBillingApi(advertiserId, userId);

  return handleFetchResponse(response);
};

export const getTotalUserAdvertisers = async search => {
  const response = await getTotalUserAdvertisersApi(search);
  const result = await handleFetchResponse(response);

  return result[HYDRA.totalItems] || 0;
};

export const updateAdvertiser = async advertiser => {
  const response = await putAdvertiserApi(advertiser.id, advertiser);

  return handleFetchResponse(response);
};

export const updateAdvertiserBlind = async advertiser => {
  const response = await putAdvertiserBlindApi(advertiser.id, advertiser);

  return handleFetchResponse(response);
};

export const getTotalAdvertiserUsers = async advertiserId => {
  const response = await getTotalAdvertiserUsersApi(advertiserId);
  const result = await handleFetchResponse(response);

  return result[HYDRA.totalItems] || 0;
};

export const getCampaigns = async id => {
  const response = await getCampaignsApi(id);

  return handleFetchResponse(response);
};

export const getAdvertiserAssignableResourcesByRoles = async advertiserId => {
  const userId = getMeUid();

  const response = await getAdvertiserAssignableResourcesByRolesApi(advertiserId, userId);

  if (response && Array.isArray(response)) {
    return response;
  }

  if (response?.data?.getAdvertiserAssignableResourcesByRoles) {
    return response.data.getAdvertiserAssignableResourcesByRoles;
  }

  throw new Error('Error while fetching advertisers assignable campaigns by roles');
};

export const searchAdvertisersUsers = async options => {
  const results = await searchAdvertisersUsersApi(options);

  return { items: results[HYDRA.member], totalItem: results[HYDRA.totalItems] };
};

export const getCampaignDetails = async campaignLegacyId => {
  const response = await getCampaignDetailsApi(campaignLegacyId);

  return handleFetchResponse(response);
};

export const getCampaignPromos = async ({ campaignLegacyId, options }) => {
  const response = await getCampaignPromosApi({ campaignLegacyId, options });

  const results = await handleFetchResponse(response);

  return { items: results[HYDRA.member], totalItem: results[HYDRA.totalItems] };
};

export default {
  connectAdvertiserResources,
  searchAdvertisers,
  getAdvertiser,
  getAdvertisers,
  getTotalUserAdvertisers,
  searchAdvertisersWithPlatform,
  searchAdvertisersWithPermissions,
  getAdvertiserWithBilling,
  updateAdvertiser,
  updateAdvertiserBlind,
  getAdvertiserUsers,
  getTotalAdvertiserUsers,
  getCampaigns,
  getAdvertiserAssignableResourcesByRoles,
  searchAdvertisersUsers,
  getCampaignDetails,
  getCampaignPromos,
};
