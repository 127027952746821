import { token } from 'timeone-components';
import { fetchWithPagination, queryParams } from '../utils';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

export const getActionsPendingApi = ({ campaignId, options }) =>
  fetchWithPagination(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/actions/pending`, {
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getActionsProcessedApi = ({ campaignId, options }) =>
  fetchWithPagination(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/actions/processed`, {
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const uploadFileApi = (campaignId, body) =>
  fetch(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/action_files`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body,
  });

export const getProcessApi = campaignId =>
  fetch(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/actions/process`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const processFileApi = (campaignId, fileId) =>
  fetch(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/action_files/${fileId}/confirm`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getExportActionsApi = (campaignId, options) =>
  fetch(
    `${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/export_actions?${queryParams(
      options
    )}`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export const deleteExportActionsApi = (campaignId, id) =>
  fetch(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/export_actions/${id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const createExportActionsPendingApi = (campaignId, body, options) =>
  fetch(
    `${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/export_actions/pending?${queryParams(
      options
    )}`,
    {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
      body: JSON.stringify(body),
    }
  );

export const createExportActionsProcessedApi = (campaignId, body, options) =>
  fetch(
    `${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/export_actions/processed?${queryParams(
      options
    )}`,
    {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
      body: JSON.stringify(body),
    }
  );

export const registerActionsApi = ({ campaignId, body }) =>
  fetch(`${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/actions`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify(body),
  });

export const getIsLastMonthValidationAvailableApi = campaignId =>
  fetch(`${process.env.REACT_APP_PROXY_PERF_API}/invoice/campaign/${campaignId}/info`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });

export const getExportActionFileApi = (campaignId, exportId) =>
  fetch(
    `${process.env.REACT_APP_ACTION_API_URL}/performance/campaigns/${campaignId}/export_actions/${exportId}/download`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );

export default {
  getActionsPendingApi,
  uploadFileApi,
  getProcessApi,
  processFileApi,
  getExportActionsApi,
  deleteExportActionsApi,
  createExportActionsPendingApi,
  createExportActionsProcessedApi,
  getIsLastMonthValidationAvailableApi,
  getExportActionFileApi,
};
