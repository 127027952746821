import { gql } from '@apollo/client';

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      firstName
      lastName
      email
      profilePicture
      permissions
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($email: String!, $resourceType: String, $resourceId: ID) {
    searchUser(email: $email, resourceType: $resourceType, resourceId: $resourceId) {
      id
      firstName
      lastName
      email
      company
    }
  }
`;

export const GET_USER_RESOURCES = gql`
  query getUserResources($id: ID!) {
    getUserResources(id: $id) {
      platform {
        id
        name
        logoPath
      }
      resources {
        publisher {
          id
          firstname
          lastname
          civility
          company
          address
          postalCode
          city
          country
          legacyId
        }
        advertiser {
          id
          name
          servicePlatformId
          legacyId
        }
      }
    }
  }
`;

export default { GET_USER_RESOURCES, GET_ME };
