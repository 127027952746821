import { gql } from '@apollo/client';

export const GET_PENDING_PUBLISHERS = gql`
  query collectionQueryPendings($campaignId: ID!) {
    collectionQueryPendings(campaignId: $campaignId) {
      provider {
        _id
        name
        legacyId
        language
        urlsite
        site {
          category {
            _id
            categoryName
          }
        }
        charterCategories {
          _id
          name
        }
        distributionMethod {
          _id
          name
        }
      }
    }
  }
`;

export const GET_MY_PUBLISHERS = gql`
  query collectionQuerySubscribedAndRefuseds($campaignId: ID!) {
    collectionQuerySubscribedAndRefuseds(campaignId: $campaignId) {
      provider {
        _id
        name
        legacyId
        language
        urlsite
        site {
          category {
            _id
            categoryName
          }
        }
        charterCategories {
          _id
          name
        }
        distributionMethod {
          _id
          name
        }
      }
      commissionGroup {
        _id
        name
      }
      status
    }
  }
`;

const publisherDetailsFields = `
  _id
  name
  urlsite
  site {
    screenshot
    urlsite
    categoryRank {
      category {
        categoryName
      }
      rank
    }
    estimatedMonthlyVisits {
      visitsNumber
      date
    }
    engagments {
      visits
      timeOnSite
      pagePerVisit
      bounceRate
    }
    countryRank {
      countryName
      rank
    }
    globalRank {
      rank
      direction
    }
    topReferrings {
      website
      change
      value
    }
    topOrganicKeywords {
      keyword
      value
      change
    }
    topCountryShares {
      country
      value
      change
    }
    topDestinations {
      website
      value
      change
    }
    topCategories {
      category {
        _id
        categoryName
      }
      affinity
    }
    trafficSources {
      search
      social
      mail
      paidReferrals
      direct
      referrals
    }
  }
`;

export const GET_PUBLISHER_DETAILS = gql`
  query itemQueryProviderDetailsForCampaign($campaignId: ID!, $providerId: ID!) {
    itemQueryProviderDetailsForCampaign(campaignId: $campaignId, providerId: $providerId) {
      provider {
        ${publisherDetailsFields}
      }
    }
  }
`;

export const GET_PUBLISHERS_DETAILS = gql`
  query collectionQueryProviders($idList: [ID]!) {
    collectionQueryProviders(id_list: $idList) {
      _id
      name
      urlsite
      site {
        screenshot
        urlsite
        categoryRank {
          category {
            categoryName
          }
          rank
        }
        estimatedMonthlyVisits {
          visitsNumber
          date
        }
        engagments {
          visits
          timeOnSite
          pagePerVisit
          bounceRate
        }
      }
    }
  }
`;

export const GET_AUTOCOMPLETE_MY_PUBLISHERS = gql`
  query collectionQuerySubscribedAndRefuseds($campaignId: ID!) {
    collectionQuerySubscribedAndRefuseds(campaignId: $campaignId) {
      provider {
        _id
        name
        legacyId
        distributionMethod {
          _id
          name
        }
      }
    }
  }
`;

export const GET_AUTOCOMPLETE_PENDING_PUBLISHERS = gql`
  query collectionQueryPendings($campaignId: ID!) {
    collectionQueryPendings(campaignId: $campaignId) {
      provider {
        _id
        name
        legacyId
      }
    }
  }
`;
