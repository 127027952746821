import { useState, useRef, useEffect } from 'react';

import { captureException } from '../utils';

export default function useApiPulling(apiFn, delay = 2000) {
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const fetchData = useRef(() => {});

  useEffect(() => {
    fetchData.current = async () => {
      try {
        const data = await apiFn();

        setResult(data);
        setError();
      } catch (e) {
        captureException({ error: e, type: e.type || 'fecthData', component: 'useApiPulling' });

        setResult();
        setError(e);
      }
    };
  }, [apiFn]);

  useEffect(() => {
    fetchData.current();

    function tick() {
      fetchData.current();
    }

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);

  return { result, error, fetch: fetchData.current };
}
