/* eslint-disable import/prefer-default-export */
import {
  getEmailingShootsApi,
  createExportEmailingShootsApi,
  getExportEmailingShootsApi,
  getEmailingShootsFileApi,
} from './emailing.api';
import { handleFetchResponse, handleFetchBlobResponse } from '../utils';

export const getEmailingShoots = async (campaignId, options) => {
  const variables = {
    campaignId,
    sendDate_after: options['date[after]'],
    sendDate_before: options['date[before]'],
  };

  return getEmailingShootsApi(variables).then(({ data }) => {
    if (data?.collectionQueryEmailingShoots) {
      return data.collectionQueryEmailingShoots;
    }

    throw new Error('Error while fetching emailing shoots');
  });
};

export const getExportEmailingShoots = async ({ campaignId, body, options }) => {
  const response = await createExportEmailingShootsApi({ campaignId, body, options });

  const { id: exportId } = await handleFetchResponse(response);

  return new Promise((resolve, reject) => {
    const computationChecker = setInterval(async () => {
      try {
        const exportResponse = await getExportEmailingShootsApi({ campaignId, id: exportId });
        const result = await handleFetchResponse(exportResponse);

        const { status, id, fileName } = result;

        if (status === 'Done') {
          clearInterval(computationChecker);
          resolve({ id, fileName });
        }

        if (status === 'Error') {
          throw new Error('Error while generated cashbacks export');
        }
      } catch (error) {
        clearInterval(computationChecker);
        reject(error);
      }
    }, 1000);
  });
};

export const exportEmailingShoots = async (campaignId, body) => {
  const { id, fileName } = await getExportEmailingShoots({ campaignId, body });
  const response = await getEmailingShootsFileApi({ campaignId, id });

  const blob = await handleFetchBlobResponse(response);

  return { fileName, blob };
};
