import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Card, TO_CONSTANTS } from 'timeone-components';

const bp = TO_CONSTANTS.responsive.breakpointName;
const isSmallScreen = query => [bp.extraSmall, bp.small].includes(query);

export const MainContainer = styled.div`
  padding: 2rem 2rem 0;

  > * {
    height: auto;
  }
`;

export const ContactContainer = styled(Card)`
  margin-top: 1.5rem;
`;

export const ContactInner = styled.div`
  display: flex;
  align-items: center;
  ${({ query }) =>
    isSmallScreen(query)
      ? css`
          flex-direction: column;
          padding: 1rem;
        `
      : css`
          padding: 50px 1rem;

          @media screen and (max-width: 1023px) {
            flex-direction: column;
          }
        `}
`;

export const ContactText = styled.div`
  text-align: center;
  ${({ query }) =>
    !isSmallScreen(query) &&
    css`
      @media screen and (min-width: 1024px) {
        width: 33.333%;
        min-width: 33.333%;
      }
    `}
`;

export const ContactTitle = styled.h1`
  font-size: 2.1rem;
  text-transform: none;
  font-weight: bold;
  margin: 0 0 0.8rem;
`;

export const ContactSubTitle = styled.p`
  font-size: 1.15rem;
  font-weight: 500;
`;

export const ContactCardsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  ${({ query }) =>
    isSmallScreen(query)
      ? css`
          flex-direction: column;
          margin-top: 1rem;
          max-width: 100%;
        `
      : css`
          margin-top: 2rem;

          @media screen and (min-width: 1024px) {
            margin-top: 0;
            width: 66.666%;
            min-width: 66.666%;
          }
        `}
`;

export const ContactCardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${({ query }) =>
    !isSmallScreen(query)
      ? css`
          width: 50%;
          min-width: 50%;
          padding: 2rem;
        `
      : css`
          padding: 0.75rem 0;
          max-width: 100%;
        `}
`;

export const ContactCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  ${({ query }) =>
    isSmallScreen(query)
      ? css`
          width: 60px;
          min-width: 60px;
          height: 60px;
          min-height: 60px;
        `
      : css`
          width: 80px;
          min-width: 80px;
          height: 80px;
          min-height: 80px;
        `}

  > img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export function getAMAvatarCss({ query }) {
  return css`
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    font-weight: 700;
    ${isSmallScreen(query)
      ? css`
          width: 60px;
          min-width: 60px;
          height: 60px;
          min-height: 60px;
        `
      : css`
          width: 80px;
          min-width: 80px;
          height: 80px;
          min-height: 80px;
        `}
  `;
}

export const ContactCardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.15rem;
  flex-grow: 1;
  max-width: calc(100% - 80px - 1rem);

  p {
    margin-bottom: 3px;
  }
`;

export const ContactCardEmailContainer = styled.p`
  color: ${({ theme }) => theme.secondary};
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: ${({ theme }) => theme.secondary};
    transition: 0.2s ease-out;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.secondary && darken(0.2, theme.secondary)};
    }
  }
`;

export const ContactCardContentTitle = styled.p`
  font-weight: 500;
`;

export default {};
