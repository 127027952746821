import styled, { css } from 'styled-components';
import { TO_CONSTANTS, Card as TOCard, Snackbar } from 'timeone-components';
import { lighten, darken } from 'polished';

export function getStatusColors(status, theme) {
  switch (status) {
    case 1:
      return { color: darken(0.3, theme.colors.green), backgroundColor: lighten(0.3, theme.colors.green) };
    case 0:
      return { color: darken(0.2, theme.colors.red), backgroundColor: lighten(0.4, theme.colors.red) };
    default:
      return { color: darken(0.3, theme.colors.gray), backgroundColor: lighten(0.2, theme.colors.gray) };
  }
}

export const StatusContainer = styled.div.attrs(({ status, theme }) => ({
  ...getStatusColors(status, theme),
}))`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 10px;
  color: ${({ color }) => color};
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin: 0 auto;
  min-width: 5.2rem;
  width: auto !important;
  padding: 5px 15px;
`;

export const PartialErrorContainer = styled(Snackbar)`
  margin: 0 auto 1rem;
`;

export const UploadCard = styled(TOCard)`
  width: calc(50% - 3rem);
  display: flex;
  flex-direction: columns;
  position: relative;

  label {
    margin: 1rem 0 0 3rem;
    position: inherit;
  }

  .error {
    margin: 0 1rem 1rem 3rem;
  }

  ul {
    padding: 1rem;

    li {
      list-style: disc;
    }
  }
`;

export const UploadButton = styled.div`
  button {
    margin-left: auto;
    min-width: 7.5rem;
  }
`;

export const UploadInputLabelContainer = styled.span`
  margin-right: 0.5rem;
  min-width: 4rem;
  font-weight: 700;
`;

export const UploadWarningContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    align-self: start;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.colors.blue};
    margin-right: 1rem;
  }
`;

export const ActionButton = styled(StatusContainer)`
  margin: 0;

  &:first-child {
    margin-right: 1rem;
  }

  &:hover {
    cursor: default;
    transition: all ${({ theme }) => theme.transition.hover};
  }

  ${({ small }) =>
    small &&
    css`
      min-width: auto;
      padding: 0.5rem;
      border-radius: 50%;
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
      }
    `}
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: default;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row-reverse;
  justify-content: center;

  & > div {
    padding: 0.5rem 0.625rem;
  }
`;

export const FooterRowContainer = styled.tr`
  height: ${({ rowHeight }) => rowHeight};
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${({ fixed }) =>
    fixed &&
    css`
      position: absolute;
      top: 0;
      background: ${({ theme }) => theme.pagination.loaderContainer.backgroundColor};
      left: 0;
    `}
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: ${rounded};
    `}
`;

export const PageContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  flex: 1;
  flex-flow: column;
`;

export const TableNoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const defaultValue = {
  controlBorderRadius: '1.5rem',
  controlHeight: '2.4rem',
  controlMinHeight: '2.4rem',
  controlWidth: 16,
  menuMarginTop: '0.25rem',
  menuWidth: '24.01rem',
  menuListBorderRadius: '0.67rem',
  menuPaddingScroll: '0.66rem 0.5rem 0.66rem 0.66rem ',
  menuPadding: 0.66,
  menuListPaddingRight: '0.35rem',
  optionPadding: '0.33rem 0.33rem',
  optionBorderRadius: '0.67rem',
  optionFontSize: '0.92rem',
  dropdownIndicatorPadding: 0.67,
  singleValueFontSize: '1rem',
  singleValuePadding: 'calc(1rem - 8px)',
  inputSingleValuePadding: 1,
  inputFontSize: '1rem',
};

const smallValue = {
  ...defaultValue,
  controlWidth: 12,
};

const extraSmallValues = {
  ...smallValue,
  dropdownIndicatorPadding: 0.5,
  singleValuePadding: 0,
  inputSingleValuePadding: 0.75,
  inputFontSize: '16px',
};

const largeValues = {
  ...defaultValue,
  controlBorderRadius: '1.125rem',
  controlWidth: 18,
  menuMarginTop: '0.31rem',
  menuWidth: '26.5rem',
  menuListBorderRadius: '0.625rem',
  menuPaddingScroll: '0.5rem 0.35rem 0.5rem 0.5rem ',
  menuPadding: 0.5,
  menuListPaddingRight: '0.3rem',
  optionPadding: '0.25rem 0.25rem',
  optionBorderRadius: '0.375rem',
  dropdownIndicatorPadding: 0.75,
  singleValueFontSize: '1.0625rem',
  optionFontSize: '1.0625rem',
};

const mediumValues = {
  ...defaultValue,
};

function getResponsiveValues(query) {
  switch (query) {
    case TO_CONSTANTS.responsive.breakpointName.large:
      return largeValues;
    case TO_CONSTANTS.responsive.breakpointName.medium:
      return mediumValues;
    case TO_CONSTANTS.responsive.breakpointName.small:
      return smallValue;
    case TO_CONSTANTS.responsive.breakpointName.extraSmall:
      return extraSmallValues;
    default:
      return mediumValues;
  }
}

export const SelectSingleValue = styled.div.attrs(({ query }) => ({
  rspV: getResponsiveValues(query),
}))`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ rspV }) => rspV.controlBorderRadius};
  border: 1px solid #ccc;
  display: flex;
  height: ${({ rspV }) => rspV.controlHeight};
  padding: 0 ${({ rspV }) => rspV.inputSingleValuePadding}rem;
  width: ${({ rspV }) => rspV.controlWidth - 2 * rspV.inputSingleValuePadding}rem;

  & > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const selectStyle = (theme, query, options) => {
  const responsiveValues = getResponsiveValues(query);

  return {
    control: (base, state) => ({
      ...base,
      borderRadius: responsiveValues.controlBorderRadius,
      height: responsiveValues.controlHeight,
      minHeight: responsiveValues.controlMinHeight,
      width: `${responsiveValues.controlWidth}rem`,
      borderColor: state.isFocused ? theme.primary : '#ccc',
      boxShadow: 'none',
      ':hover': {
        transition: 'all 0.5s ease',
        cursor: 'pointer',
        borderColor: theme.primary,
      },
    }),
    singleValue: singleValueBase => ({
      ...singleValueBase,
      fontSize: responsiveValues.singleValueFontSize,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: responsiveValues.singleValuePadding,
    }),
    placeholder: placeholderBase => ({
      ...placeholderBase,
      padding: 0,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    menu: menuBase => ({
      ...menuBase,
      borderRadius: responsiveValues.menuListBorderRadius,
      boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.24)',
      marginTop: responsiveValues.menuMarginTop,
      padding: options.length < 11 ? `${responsiveValues.menuPadding}rem` : responsiveValues.menuPaddingScroll,
    }),
    menuList: menuListBase => ({
      ...menuListBase,
      paddingRight: options.length < 11 ? 0 : responsiveValues.menuListPaddingRight,
      paddingTop: 0,
      paddingBottom: 0,
      scrollbarColor: `${theme.colors.scrollBar} ${theme.colors.white}`,
      scrollbarWidth: 'thin',
      scrollbarArrowColor: theme.colors.scrollBar,
      scrollbarFaceColor: theme.colors.scrollBar,
      scrollbarShadowColor: theme.colors.scrollBar,
      '::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.colors.scrollBar,
      },
      '::-webkit-scrollbar-track': {
        borderRadius: responsiveValues.menuListBorderRadius,
        background: `${theme.colors.scrollBarLight}`,
        border: `0.17rem solid ${theme.colors.white}`,
      },
    }),
    option: optionBase => ({
      ...optionBase,
      backgroundColor: theme.colors.white,
      color: theme.primary,
      fontSize: responsiveValues.optionFontSize,
      padding: responsiveValues.optionPadding,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.hoverMenuList,
        borderRadius: responsiveValues.optionBorderRadius,
      },
    }),
    indicatorSeparator: indicatorSeparatorBase => ({
      ...indicatorSeparatorBase,
      display: 'none',
    }),
    dropdownIndicator: dropdownIndicatorBase => ({
      ...dropdownIndicatorBase,
      cursor: 'pointer',
      padding: `0 ${responsiveValues.dropdownIndicatorPadding}rem`,
      color: theme.primary,
      '> svg': {
        width: '1rem',
      },
    }),
    input: inputBase => ({
      ...inputBase,
      color: theme.primary,
      paddingLeft: responsiveValues.singleValuePadding,
      fontSize: responsiveValues.inputFontSize,
    }),
  };
};

export const CampaignsContainer = styled.div`
  padding-bottom: 1rem;
  margin-right: auto;
`;

export const CampaignPeriodContainer = styled.div`
  display: flex;
`;

export const MailTo = styled.a`
  margin-left: 0.2rem;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContainerContactMessage = styled.div`
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

export const ParagraphContactMessage = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
`;
