import { token } from 'timeone-components';
import { fetchWithPagination, queryParams } from '../utils';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersLd = {
  ...headers,
  accept: 'application/ld+json',
};

const headersPatch = {
  ...headers,
  'Content-Type': 'application/merge-patch+json',
};

export function getHighlightsProcessedApi({ campaignId, options }) {
  return fetchWithPagination(`${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignId}/highlights`, {
    method: 'GET',
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
}

export function getHighlightsUnprocessedApi({ campaignId, options }) {
  return fetchWithPagination(`${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignId}/highlights/pending`, {
    method: 'GET',
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
}

export function getHighlightsAttachmentsApi({ downloadDocsUrl }) {
  return fetch(downloadDocsUrl, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
}

export function updateHighlightStatusApi({ campaignId, highlightId, status }) {
  return fetch(`${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignId}/highlights/${highlightId}`, {
    method: 'PATCH',
    headers: {
      ...headersPatch,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
    body: JSON.stringify({ status }),
  });
}

export function getHighlightsStatusApi({ options }) {
  return fetchWithPagination(`${process.env.REACT_APP_PERFORMANCE_API_URL}/highlight_statuses`, {
    method: 'GET',
    options,
    headers: {
      ...headersLd,
      Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
    },
  });
}

export const getHighlightsFileApi = ({ campaignId, options }) =>
  fetch(
    `${process.env.REACT_APP_PERFORMANCE_API_URL}/programs/${campaignId}/highlights/export?${queryParams(options)}`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    }
  );
