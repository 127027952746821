import { useContext } from 'react';
import { AdvertisersContext } from '../Providers';
import useRouter from '../App/useRouter';

export default function useStatisticsPath() {
  const { selectedCampaign } = useContext(AdvertisersContext);
  const { routes } = useRouter({ permissions: selectedCampaign.permissions });

  return routes.find(({ path }) => path === '/statistics') ? '/statistics' : null;
}
