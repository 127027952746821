import { ApolloClient } from '@apollo/client';
import { apolloBoostDefaultConfig } from 'timeone-components';

export const client = new ApolloClient(
  apolloBoostDefaultConfig(
    process.env.REACT_APP_GRAPHQL_USER_API_URL,
    `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/oauth`
  )
);
export const stitchingClient = new ApolloClient(
  apolloBoostDefaultConfig(
    process.env.REACT_APP_GRAPHQL_GATEWAY_API_URL,
    `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/oauth`
  )
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

client.defaultOptions = { ...defaultOptions };
stitchingClient.defaultOptions = { ...defaultOptions };

export function getMeUid() {
  const uid = document.cookie
    .split('; ')
    .map(attribute => {
      const [key, value] = attribute.split('=');

      return key === 'UID' ? value : null;
    })
    .filter(Boolean)
    .pop();

  return uid ? parseInt(uid, 10) : null;
}

export function queryParams(params = {}) {
  return Object.keys(params)
    .map(param => {
      if (Array.isArray(params[param])) {
        const paramName = encodeURIComponent(`${param}[]`);

        return params[param].map(query => `${paramName}=${encodeURIComponent(query)}`).join('&');
      }

      return `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`;
    })
    .join('&');
}

class HttpError extends Error {
  constructor({ message = 'Something went wrong but no information is given from API', violations, context, status }) {
    super(message);
    this.type = 'http';
    this.violations = violations;
    this.context = context;
    this.status = status;
  }
}

async function handleReponse(response) {
  if (!response.ok) {
    const body = await response.json();
    const { detail, violations } = body;

    const formatViolations = violations?.reduce((acc, value) => ({ ...acc, [value.propertyPath]: value.message }), {});

    const { type, redirected, status, statusText, headers, url, togContext } = response;

    const context = {
      request: togContext?.request,
      response: {
        detail,
        headers: [...headers].reduce((acc, header) => ({ ...acc, [header[0]]: header[1] }), {}),
        redirected,
        status,
        statusText,
        type,
        url,
        violations: formatViolations,
      },
    };

    throw new HttpError({ message: detail, violations: formatViolations, context, status });
  }

  return response;
}

export async function handleFetchResponse(response) {
  const res = await handleReponse(response);

  return res.json();
}

export async function handleFetchBlobResponse(response) {
  const res = await handleReponse(response);

  return res.blob();
}

export async function queryGraphqlWithPagination(query, { options, variables = {} }) {
  const { limit, offset, itemsPerPage, ...restOptions } = options;
  const graphqlOptions = {
    ...restOptions,
    itemsPerPage,
    page: 1,
  };

  if (offset > 0) {
    graphqlOptions.page = offset / itemsPerPage + 1;
  }

  if (limit > itemsPerPage) {
    const queries = [];
    const numberOfPages = Math.ceil(limit / itemsPerPage);

    for (let i = 0; i < numberOfPages; i += 1) {
      queries.push({
        ...graphqlOptions,
        page: graphqlOptions.page + i,
      });
    }

    const results = await Promise.all(
      queries.map(queryOptions =>
        client.query({
          ...query,
          variables: { ...variables, options: queryOptions },
        })
      )
    );

    return results.reduce((items, result) => [...items, ...result.data[query.name]], []);
  }

  return client.query({
    ...query,
    variables: { ...variables, options: graphqlOptions },
  });
}

export async function fetchWithPagination(url, { options, headers }) {
  const { limit, offset, itemsPerPage, ...restOptions } = options;
  const fetchOptions = {
    ...restOptions,
    itemsPerPage,
    page: 1,
  };

  if (offset > 0) {
    fetchOptions.page = offset / itemsPerPage + 1;
  }

  if (limit > itemsPerPage) {
    const calls = [];
    const numberOfPages = Math.ceil(limit / itemsPerPage);

    for (let i = 0; i < numberOfPages; i += 1) {
      calls.push({
        ...fetchOptions,
        page: fetchOptions.page + i,
      });
    }

    const responses = await Promise.all(
      calls.map(opts =>
        fetch(`${url}?${queryParams(opts)}`, {
          headers: {
            ...headers,
            accept: 'application/ld+json',
          },
        })
      )
    );

    const results = await Promise.all(responses.map(handleFetchResponse));

    return results.reduce(
      (items, result) => ({ ...result, 'hydra:member': [...items['hydra:member'], ...result['hydra:member']] }),
      { 'hydra:member': [] }
    );
  }

  const response = await fetch(`${url}?${queryParams(fetchOptions)}`, {
    headers: {
      ...headers,
      accept: 'application/ld+json',
    },
  });

  return handleFetchResponse(response);
}

export default { client, getMeUid, queryParams, handleFetchResponse, queryGraphqlWithPagination, fetchWithPagination };
