import { useEffect, useState, useCallback } from 'react';

import serviceUser from '../services/user';
import { captureException } from '../utils';

function getUserPreferences(preferenceEntityId, preferenceKeyBase) {
  const key = `${preferenceKeyBase}:${preferenceEntityId}`;

  return serviceUser.getUserPreference(key);
}

export default function usePreferences({ preferenceEntityId, preferenceKeyBase, onSuccess }) {
  const [userPreferences, setUserPreferences] = useState();

  async function fetchUserPreferences(id, keyBase) {
    const [preferences] = await getUserPreferences(id, keyBase);

    const usablePreferences = preferences ? { ...preferences, value: JSON.parse(preferences.value) } : {};

    setUserPreferences(usablePreferences);
  }

  useEffect(() => {
    if (preferenceEntityId && preferenceKeyBase) {
      setUserPreferences();
      fetchUserPreferences(preferenceEntityId, preferenceKeyBase);
    }
  }, [preferenceEntityId, preferenceKeyBase]);

  const savePreference = useCallback(
    async (id, payload) => {
      const key = `${preferenceKeyBase}:${preferenceEntityId}`;

      try {
        if (id) {
          await serviceUser.updateUserPreference(id, key, payload);
        } else {
          await serviceUser.createUserPreference(key, payload);
        }

        fetchUserPreferences(preferenceEntityId, preferenceKeyBase);

        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        captureException({ error, type: error.type || 'fecthData', component: 'usePreferences' });
      }
    },
    [preferenceEntityId, preferenceKeyBase]
  );

  return {
    savePreference,
    userPreferences,
  };
}
