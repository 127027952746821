import { token } from 'timeone-components';
import localStorageService from '../localStorage';

const headers = {
  'Content-Type': 'application/json',
};

function addAdvertiserOnPayload(payload) {
  const selectedAdvertiser = localStorageService.getSelectedAdvertiser();

  const payloadOW = { ...payload };

  if (!payloadOW.filters) {
    payloadOW.filters = {};
  }

  if (!payloadOW.filters.advertiserId) {
    payloadOW.filters.advertiserId = [];
  }

  payloadOW.filters.advertiserId.push(selectedAdvertiser.legacyId.toString());

  return payloadOW;
}

function checkResponseError(res) {
  if (res.response && (res.response.status === 'KO' || res.response.errors)) {
    throw new Error(res.response.errors);
  }

  return res;
}

function buildFetchConfig(url) {
  return () =>
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        ...headers,
        authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    })
      .then(response => token.handleFetchAuthenticationError(response))
      .then(response => response.json())
      .then(checkResponseError);
}

function buildFetchConfigByAdvertiserId(url, advertiserId) {
  return () =>
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        ...headers,
        authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
      body: JSON.stringify({
        advertiserId: parseInt(advertiserId, 10),
      }),
    })
      .then(response => token.handleFetchAuthenticationError(response))
      .then(response => response.json())
      .then(checkResponseError);
}

function buildFetchData(url) {
  return payload =>
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
      headers: {
        ...headers,
        authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    })
      .then(response => token.handleFetchAuthenticationError(response))
      .then(response => response.json());
}

/* eslint-disable-next-line */
function buildRequestExportFile(url) {
  /* eslint-disable-next-line */
  return payload =>
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(addAdvertiserOnPayload(payload)),
      headers: {
        ...headers,
        authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    })
      .then(response => token.handleFetchAuthenticationError(response))
      .then(response => response.json());
}

function buildFetchExportStatus(url) {
  return computationId =>
    fetch(`${url}/${computationId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        ...headers,
        authorization: `Bearer ${token.getOrRedirectToSSO()}`,
      },
    })
      .then(response => token.handleFetchAuthenticationError(response))
      .then(response => response.json());
}

export default {
  buildFetchConfig,
  buildFetchConfigByAdvertiserId,
  buildFetchData,
  buildRequestExportFile,
  buildFetchExportStatus,
};
