import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AdvertiserSelectContext = React.createContext({
  disabledInputSelect: true,
  setDisabledInputSelect: () => {},
});

function AdvertiserSelectProvider({ children }) {
  const [disabledInputSelect, setDisabledInputSelect] = useState(true);

  const context = useMemo(
    () => ({
      disabledInputSelect,
      setDisabledInputSelect,
    }),
    [disabledInputSelect]
  );

  return <AdvertiserSelectContext.Provider value={context}>{children}</AdvertiserSelectContext.Provider>;
}

AdvertiserSelectProvider.propTypes = {
  children: PropTypes.PropTypes.node.isRequired,
};

export default AdvertiserSelectProvider;
