import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage, ErrorPageContainer } from './styledComponents';

export default function ErrorPage({ type, message }) {
  return (
    <ErrorPageContainer>
      <p>{type}</p>
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorPageContainer>
  );
}

ErrorPage.defaultProps = {
  type: '404',
  message: 'Not found',
};

ErrorPage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};
