import React, { useContext, useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { ThemeContext } from 'styled-components';
import { ApplicationLayoutContext, TO_CONSTANTS } from 'timeone-components';
import pDebounce from 'p-debounce';

import { selectStyle, SingleAdvertiser } from './styledComponents';
import { AdvertisersContext } from '../../../Providers';
import { advertiserService, userService } from '../../../services';
import { captureException } from '../../../utils';

const MAX_MENU_HEIGHT = {
  large: 280,
  defaultHeight: 210,
};

function getMenuHeight(query) {
  return query === TO_CONSTANTS.responsive.breakpointName.large ? MAX_MENU_HEIGHT.large : MAX_MENU_HEIGHT.defaultHeight;
}

export const selectNbOptionsMax = 200;

export function optionFormat({ name, legacyId, id }) {
  return {
    label: `${name} (#${legacyId})`,
    value: id,
  };
}

const PREFERENCE_SEARCH_HISTORY_KEY = 'advertiser:header:select:search:history';

function getSearchHistoryPreferences() {
  return userService.getUserPreference(PREFERENCE_SEARCH_HISTORY_KEY);
}

function createOrUpdateSearchHistoryPreferences(preference, history) {
  if (preference?.id) {
    return userService.updateUserPreference(preference.id, PREFERENCE_SEARCH_HISTORY_KEY, history);
  }

  return userService.createUserPreference(PREFERENCE_SEARCH_HISTORY_KEY, history);
}

export function sortOptionsFormated(advertisers) {
  return advertisers.map(optionFormat);
}

function formatOptionLabelSearch(option, { inputValue }) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={option.label}
      highlightStyle={{ backgroundColor: 'transparent', fontWeight: 700 }}
    />
  );
}

const searchAdvertiser = pDebounce(
  value =>
    advertiserService
      .searchAdvertisers(value, { status: true })
      .then(data => sortOptionsFormated(data))
      .catch(error => {
        captureException({ error, type: error.type || 'fecthData', component: 'HeaderSelect' });
      }),
  500
);

// eslint-disable-next-line sonarjs/cognitive-complexity
function HeaderSelect() {
  const theme = useContext(ThemeContext);
  const { advertisers, setSelectedAdvertiserFromId, selectedAdvertiser } = useContext(AdvertisersContext);
  const { media } = useContext(ApplicationLayoutContext);

  const [options, setOptions] = useState();
  const [searchHistoryPreference, setSearchHistoryPreference] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  const handleChange = async ({ label, value } = {}) => {
    setSelectedAdvertiserFromId(value);

    if (advertisers?.length >= selectNbOptionsMax) {
      const newSearchHistory = [
        { label, value },
        ...searchHistory.filter(option => option.value !== value).slice(0, 9),
      ];

      setSearchHistory(newSearchHistory);

      try {
        const preferences = await createOrUpdateSearchHistoryPreferences(searchHistoryPreference, newSearchHistory);

        setSearchHistoryPreference(preferences);
      } catch (error) {
        captureException({ error, type: error.type || 'fecthData', component: 'HeaderSelect' });
      }
    }
  };

  async function fetchSearchHistory() {
    try {
      const preference = await getSearchHistoryPreferences();

      if (preference.length > 0) {
        setSearchHistoryPreference(preference[0]);
        setSearchHistory(JSON.parse(preference[0].value));
      }
    } catch (error) {
      captureException({ error, type: error.type || 'fecthData', component: 'HeaderSelect' });
    }
  }

  useEffect(() => {
    fetchSearchHistory();
  }, []);

  useEffect(() => {
    if (advertisers.length > 1) {
      setOptions(sortOptionsFormated(advertisers));
    }
  }, [advertisers]);

  useEffect(() => {
    if (selectedAdvertiser) {
      setInitialValues(optionFormat(selectedAdvertiser));
    }
  }, [selectedAdvertiser]);

  const LoadOptionsMemoize = useCallback(searchAdvertiser, []);

  if (advertisers.length >= selectNbOptionsMax) {
    return (
      <Async
        className={`advertiser-header--select-${media.query}`}
        styles={selectStyle(theme, media.query, options, true)}
        isSearchable
        value={initialValues}
        loadOptions={LoadOptionsMemoize}
        defaultOptions={searchHistory}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabelSearch}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? `No result found for "${inputValue}"` : 'Enter advertiser name'
        }
        blurInputOnSelect
        maxMenuHeight={getMenuHeight(media.query)}
      />
    );
  }

  if (advertisers.length > 1) {
    return (
      <Select
        className={`advertiser-header--select-${media.query}`}
        styles={selectStyle(theme, media.query, options)}
        isSearchable
        value={initialValues}
        options={options}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabelSearch}
        noOptionsMessage={() => 'No match'}
        blurInputOnSelect
        maxMenuHeight={getMenuHeight(media.query)}
      />
    );
  }

  return (
    <SingleAdvertiser query={media.query}>
      <div>{`${advertisers[0].name} (#${advertisers[0].legacyId})`}</div>
    </SingleAdvertiser>
  );
}

function HeaderSelectWithOnboardingMarker(props) {
  return (
    <div data-onboarding="select-advertiser">
      <HeaderSelect {...props} />
    </div>
  );
}

export default HeaderSelectWithOnboardingMarker;
