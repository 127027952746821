import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, SwitchLabel, Switch } from 'timeone-components';

import { ToggleLabel } from './styledComponents';

const USER_STATUS = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
};

function ConfirmButton({ onClick, actionName }) {
  const [submitting, setSumitting] = useState(false);

  const handleClick = useCallback(
    async e => {
      setSumitting(true);
      await onClick(e);
    },
    [onClick]
  );

  return (
    <Button appearance="rounded" submitting={submitting} fill onClick={handleClick}>
      {actionName}
    </Button>
  );
}

ConfirmButton.propTypes = {
  onClick: PropTypes.func,
  actionName: PropTypes.string,
};

export default function SetUserStatus({ rowData, updateStatus }) {
  const [checked, setChecked] = useState(rowData.status !== USER_STATUS.inactive);
  const [confirmProps, setConfirmProps] = useState({ isOpen: false });

  const name = `user-${rowData.id}-status`;

  useEffect(() => {
    setChecked(rowData.status !== USER_STATUS.inactive);
  }, [rowData]);

  function onCancel() {
    setConfirmProps({ isOpen: false });
  }

  async function onChangeStatus(status) {
    await updateStatus(rowData.id, rowData.email, status);
    setConfirmProps({ isOpen: false });
  }

  function onChange(email) {
    return function changeStatus(e) {
      e.stopPropagation();
      const nextStatus = e.target.checked;

      setConfirmProps({
        isOpen: true,
        onClick: () => onChangeStatus(nextStatus),
        onCancel,
        message: nextStatus
          ? `The user ${email} will be active on all his previous accounts, are you sure ?`
          : `The user ${email} will be inactive for all the accounts, are you sure ?`,
        actionName: nextStatus ? 'Activate' : 'Deactivate',
        ConfirmButton,
      });
    };
  }

  return (
    <>
      {confirmProps.isOpen && <Confirm {...confirmProps} />}
      <SwitchLabel htmlFor={name}>
        <ToggleLabel>Inactive</ToggleLabel>
        <input type="checkbox" id={name} checked={checked} onChange={onChange(rowData.email)} />
        <Switch />
        <ToggleLabel>{rowData.status === USER_STATUS.pending ? 'Pending' : 'Active'}</ToggleLabel>
      </SwitchLabel>
    </>
  );
}

SetUserStatus.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    email: PropTypes.string,
  }),
  updateStatus: PropTypes.func,
};
