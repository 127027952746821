import { themes } from 'timeone-components';
import { lighten, darken } from 'polished';

const theme = themes.advertiser;

const overwriteTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    selectHoverGray: 'rgba(128, 134, 148, 1)',
    selectDefaultGray: 'rgba(78, 84, 97, 1)',
    scrollBar: 'rgba(204, 215, 229, 1)',
    scrollBarLight: 'rgba(204, 215, 229, 0.4)',
    hoverMenuList: 'rgba(48, 55, 71, 0.1)',
    publisherPreviewGraph: '#79D8EB',
    pieDetail: {
      other: '#D8D8D8',
      active: '#99F775',
    },
  },
  table: {
    ...theme.table,
    labelCell: {
      color: {
        red: darken(0.2, theme.colors.red),
        blue: darken(0.3, theme.colors.blue),
        green: darken(0.3, theme.colors.green),
        gray: darken(0.3, theme.colors.gray),
        disabled: darken(0.3, theme.colors.gray),
        orange: darken(0.3, theme.colors.orange),
      },
      backgroundColor: {
        orange: lighten(0.3, theme.colors.orange),
        red: lighten(0.4, theme.colors.red),
        blue: lighten(0.3, theme.colors.blue),
        gray: lighten(0.25, theme.colors.gray),
        disabled: lighten(0.2, theme.colors.gray),
        green: lighten(0.3, theme.colors.green),
      },
    },
  },
};

export default overwriteTheme;
