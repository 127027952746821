import React, { useState, useEffect, useCallback, useContext } from 'react';
import { token } from 'timeone-components';
import Notifications from '@timeone/notifications-js';

import { AdvertisersContext } from '../../Providers';
import { captureException } from '../../utils';

import { TEMPLATES, MESSAGE_KEYS } from './constants';
import * as templates from './templates';
import * as messages from './messages';

export default function Notif() {
  const { selectedAdvertiser } = useContext(AdvertisersContext);
  const [Display, setDisplay] = useState();

  const scopeCallback = useCallback(({ data, onView, next }) => {
    const Template = templates[TEMPLATES[data?.template?.id]];
    const Content = messages[MESSAGE_KEYS[data?.messageKey?.id]];

    if (Template && Content) {
      setDisplay(<Template key={Date.now()} data={data} onView={onView} next={next} Content={Content} />);
    } else {
      captureException({
        error: new Error(`template id ${data?.template?.id} or messageKey id ${data?.messageKey?.id} is not supported`),
        type: 'component',
        component: 'Notifications',
      });

      next();
    }
  }, []);

  useEffect(() => {
    if (selectedAdvertiser.id) {
      const advertiserIdScope = `advertiser/${selectedAdvertiser.id}`;
      const advertiserScope = `advertiser`;

      const notifications = Notifications({
        productId: 1,
        apiUrl: process.env.REACT_APP_NOTIFICATION_API_URL,
        addHeaders: () => ({ Authorization: `Bearer ${token.getOrRedirectToSSO()}` }),
        onError: error => {
          captureException({
            error,
            type: 'component',
            component: 'Notifications',
          });
        },
        refetch: true,
        refetchDelay: 900,
      });

      notifications.register(advertiserIdScope, scopeCallback);
      notifications.register(advertiserScope, scopeCallback);

      notifications.listen();

      return notifications.destroy;
    }

    return () => {};
  }, [selectedAdvertiser.id]);

  if (!Display) {
    return null;
  }

  return Display;
}
