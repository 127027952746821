import React from 'react';
import { assets } from 'timeone-components';

import { Title } from './styledComponents';

const { TriangleWarningIcon } = assets.icons;

export default function Invoicing() {
  return (
    <>
      <Title>
        <TriangleWarningIcon /> Late payment
      </Title>
      <p>One or more invoices issued more than two months ago are not paid. </p>
      <p>Please contact your accountancy department to pay this invoice as quickly as possible.</p>
      <br />
    </>
  );
}
