import React, { useContext } from 'react';
import { ApplicationLayoutContext } from 'timeone-components';

import { AdvertiserSelectContext } from '../Providers';
import HeaderSelect from './components/HeaderSelect';
import { getResponsiveValues } from './components/HeaderSelect/styledComponents';
// const { WrenchIcon } = assets.icons;

function CustomHeaderSelect() {
  const { disabledInputSelect } = useContext(AdvertiserSelectContext);
  const { media } = useContext(ApplicationLayoutContext);
  const { controlWidth } = getResponsiveValues(media.query);

  return !disabledInputSelect ? <HeaderSelect /> : <div style={{ width: `${controlWidth}rem` }} />;
}

export default {
  title: 'Advertiser',
  headerBlockLeftChildren: () => <CustomHeaderSelect />,
};
