import {
  getActionsPendingApi,
  getActionsProcessedApi,
  uploadFileApi,
  getProcessApi,
  processFileApi,
  getExportActionsApi,
  deleteExportActionsApi,
  registerActionsApi,
  getIsLastMonthValidationAvailableApi,
  createExportActionsPendingApi,
  createExportActionsProcessedApi,
  getExportActionFileApi,
} from './actions.api';
import { handleFetchResponse, handleFetchBlobResponse } from '../utils';

export const getActionsPending = async payload => {
  const result = await getActionsPendingApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const getActionsProcessed = async payload => {
  const result = await getActionsProcessedApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const uploadFile = async ({ campaignId, file, isPreviousMonth }) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('isPreviousMonth', !!isPreviousMonth);

  const response = await uploadFileApi(campaignId, formData);

  return handleFetchResponse(response);
};

export const getProcess = async campaignId => {
  const response = await getProcessApi(campaignId);

  return handleFetchResponse(response);
};

export const processFile = async (campaignId, fileId) => {
  const response = await processFileApi(campaignId, fileId);

  return handleFetchResponse(response);
};

export const valideActions = async ({ campaignId, actionIds, isPreviousMonth }) => {
  const body = {
    isPreviousMonth,
    actions: actionIds.map(id => ({
      id,
      isValidated: true,
      actionDate: new Date().toISOString(),
    })),
  };

  const response = await registerActionsApi({ campaignId, body });

  return handleFetchResponse(response);
};

export const refuseActions = async ({ campaignId, actionIds }) => {
  const body = {
    isPreviousMonth: false,
    actions: actionIds.map(id => ({
      id,
      isValidated: false,
      actionDate: new Date().toISOString(),
    })),
  };

  const response = await registerActionsApi({ campaignId, body });

  return handleFetchResponse(response);
};

export const getExportActions = async (campaignId, options) => {
  const response = await getExportActionsApi(campaignId, options);

  return handleFetchResponse(response);
};

export const deleteExportActions = async (campaignId, id) => {
  const response = await deleteExportActionsApi(campaignId, id);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }
};

export const createExportActionsPending = async (campaignId, body, options) => {
  const response = await createExportActionsPendingApi(campaignId, body, options);

  return handleFetchResponse(response);
};

export const getExportActionsProcessed = async (campaignId, body, options) => {
  const response = await createExportActionsProcessedApi(campaignId, body, options);

  const { id } = await handleFetchResponse(response);

  return new Promise((resolve, reject) => {
    const computationChecker = setInterval(async () => {
      try {
        const exportActionResponse = await getExportActions(campaignId, {
          itemsPerPage: 5,
          'order[id]': 'DESC',
          typeExport: 'processed',
        });

        const currentExport = exportActionResponse?.find(({ id: exportId }) => exportId === id);

        if (currentExport?.status === 'Done') {
          clearInterval(computationChecker);
          resolve({ id, filePath: currentExport.filePath });
        }
      } catch (error) {
        clearInterval(computationChecker);
        reject(error);
      }
    }, 1000);
  });
};

export const exportActionsProcessed = async (campaignId, body, options) => {
  const { id, filePath } = await getExportActionsProcessed(campaignId, body, options);
  const response = await getExportActionFileApi(campaignId, id);

  const blob = await handleFetchBlobResponse(response);

  return { fileName: filePath, blob };
};

export const getIsLastMonthValidationAvailable = async campaignId => {
  const response = await getIsLastMonthValidationAvailableApi(campaignId);

  return handleFetchResponse(response);
};

export default {
  getActionsPending,
  getActionsProcessed,
  uploadFile,
  getProcess,
  processFile,
  getExportActions,
  deleteExportActions,
  createExportActionsPending,
  exportActionsProcessed,
  valideActions,
  refuseActions,
  getIsLastMonthValidationAvailable,
};
