/* eslint-disable sonarjs/no-duplicate-string */
import {
  getHighlightsProcessedApi,
  getHighlightsUnprocessedApi,
  getHighlightsAttachmentsApi,
  updateHighlightStatusApi,
  getHighlightsStatusApi,
  getHighlightsFileApi,
} from './highlights.api';
import { handleFetchResponse, handleFetchBlobResponse } from '../utils';

export const getHighlightsProcessed = async payload => {
  const result = await getHighlightsProcessedApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const getHighlightsUnprocessed = async payload => {
  const result = await getHighlightsUnprocessedApi(payload);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
};

export const exportHighlightsAttachments = async ({ downloadDocsUrl, fileName }) => {
  const response = await getHighlightsAttachmentsApi({ downloadDocsUrl });

  const blob = await handleFetchBlobResponse(response);

  return { fileName: `${fileName}.zip`, blob };
};

export const updateHighlightStatus = async ({ campaignId, highlightId, status, key }) => {
  const response = await updateHighlightStatusApi({ campaignId, highlightId, status, key });

  return handleFetchResponse(response);
};

export const getHighlightsStatus = async options => {
  const response = await getHighlightsStatusApi(options);

  return {
    items: response['hydra:member'],
    totalItem: response['hydra:totalItems'],
  };
};

export const exportHighlights = async (campaignId, options) => {
  const response = await getHighlightsFileApi({ campaignId, options });

  const blob = await handleFetchBlobResponse(response);

  return {
    fileName: `highlight_${campaignId}_${options.startDate}_${options.endDate}_${Date.now()}.xlsx`,
    blob,
  };
};
