/* eslint-disable import/prefer-default-export */
import { getAvailableRoleForResourceApi, getRolesApi } from './roles.api';
import { handleFetchResponse } from '../utils';

export const getAssignableRoleForResource = async ({ id, type }) => {
  const response = await getAvailableRoleForResourceApi({ id, type });

  return handleFetchResponse(response);
};

export const getRoles = async () => {
  const response = await getRolesApi();

  return handleFetchResponse(response);
};

export const getPermissionsByRolesId = async () => {
  const response = await getRolesApi();

  const roles = await handleFetchResponse(response);

  return roles.reduce((acc, role) => {
    const permissions = [...new Set(role.permissions.map(({ permission }) => permission))];

    return { ...acc, [role.id]: permissions };
  }, {});
};
