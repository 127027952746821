import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

import CONSTANTS from './constants';
import { captureException } from './utils';

export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function sanitizeString(value) {
  return value ? value.toString().replace(/_/g, ' ').replace(/\//g, ' / ') : value;
}

export function formatUnknownText(text) {
  if (!text || text.toString().match(/null/i) || text.toString().match(/undefined/i)) {
    return CONSTANTS.noText;
  }

  if (text.toString().match(/unknown/i)) {
    return CONSTANTS.unknownText;
  }

  return String(text);
}

export function formatFilter(formatFilterValue) {
  return formatFilterValue.map(value => ({
    ...value,
    name: formatUnknownText(sanitizeString(value.name)),
  }));
}

export function formatFilterPublisher(formatFilterValue, term) {
  const dataFiltered = formatFilterValue
    .filter(({ provider }) => {
      try {
        const sanitizedPublisherName = removeAccents(String(provider.name).trim().toLowerCase());
        const sanitizedTerm = removeAccents(term.trim().toLowerCase());

        return String(provider.legacyId).indexOf(term) !== -1 || sanitizedPublisherName.indexOf(sanitizedTerm) !== -1;
      } catch (e) {
        captureException({ error: e, type: e.type || 'function', component: 'formatFilterPublisher' });

        return false;
      }
    })
    .map(data => ({
      ...data,
      provider: {
        ...data.provider,
        legacyLabel: `#${data?.provider?.legacyId} - ${data?.provider?.name}`,
      },
    }));

  return defaultOrderBy({ data: dataFiltered, order: { orderBy: 'provider.name' } }).slice(0, 15);
}

export function formatFilterShootId(formatFilterValue, term) {
  const dataFiltered = formatFilterValue.filter(({ shootId }) => {
    try {
      return String(shootId).indexOf(term) !== -1;
    } catch (e) {
      captureException({ error: e, type: e.type || 'function', component: 'formatFilterShootId' });
      return false;
    }
  });

  return defaultOrderBy({ data: dataFiltered, order: { orderBy: 'shootId' } }).slice(0, 15);
}

export function formatFilterPromoSubject(formatFilterValue, term) {
  const dataFiltered = formatFilterValue.filter(({ promosSubjects }) => {
    try {
      const sanitizedPromoSubject = removeAccents(String(promosSubjects.subject).trim().toLowerCase());
      const sanitizedTerm = removeAccents(term.trim().toLowerCase());

      return (
        String(promosSubjects.subjectId).indexOf(term) !== -1 || sanitizedPromoSubject.indexOf(sanitizedTerm) !== -1
      );
    } catch (e) {
      captureException({ error: e, type: e.type || 'function', component: 'formatFilterPromoSubject' });
      return false;
    }
  });

  const uniqSubjects = uniqBy(dataFiltered, 'promosSubjects.subjectId');

  return defaultOrderBy({ data: uniqSubjects, order: { orderBy: 'promosSubjects.subject' } }).slice(0, 15);
}

export function formatFilterKits(formatFilterValue, term) {
  const dataFiltered = formatFilterValue.filter(({ promos }) => {
    try {
      const sanitizedKit = removeAccents(String(promos.promoDesc).trim().toLowerCase());
      const sanitizedTerm = removeAccents(term.trim().toLowerCase());

      return String(promos.promoid).indexOf(term) !== -1 || sanitizedKit.indexOf(sanitizedTerm) !== -1;
    } catch (e) {
      captureException({ error: e, type: e.type || 'function', component: 'formatFilterKits' });
      return false;
    }
  });

  const uniqSubjects = uniqBy(dataFiltered, 'promos.promoid');

  return defaultOrderBy({ data: uniqSubjects, order: { orderBy: 'promos.promoid' } }).slice(0, 15);
}

export function formatFilterCommissionGroup(formatFilterValue, term) {
  return formatFilterValue
    .filter(({ name }) => {
      try {
        const sanitizedCommissionGroupName = removeAccents(String(name).trim().toLowerCase());
        const sanitizedTerm = removeAccents(term.trim().toLowerCase());

        return sanitizedCommissionGroupName.indexOf(sanitizedTerm) !== -1;
      } catch (e) {
        captureException({ error: e, type: e.type || 'function', component: 'formatFilterCommissionGroup' });
        return false;
      }
    })
    .map(commissionGroup => ({
      ...commissionGroup,
      name: formatUnknownText(sanitizeString(commissionGroup.name)),
    }));
}

export function formatFilterDistributionMethod(formatFilterValue) {
  return formatFilterValue.map(distributionMethod => ({
    ...distributionMethod,
    name: formatUnknownText(sanitizeString(distributionMethod.name)),
  }));
}

export function formatFilterTypology(formatFilterValue, term) {
  const dataFiltered = formatFilterValue
    .filter(({ provider }) => {
      try {
        if (!provider?.distributionMethod) {
          return false;
        }

        const sanitizedTypologyName = removeAccents(String(provider?.distributionMethod?.name).trim().toLowerCase());
        const sanitizedTerm = removeAccents(term.trim().toLowerCase());

        return (
          String(provider?.distributionMethod?._id).indexOf(term) !== -1 ||
          sanitizedTypologyName.indexOf(sanitizedTerm) !== -1
        );
      } catch (e) {
        captureException({ error: e, type: e.type || 'function', component: 'formatFilterTypology' });

        return false;
      }
    })
    .map(({ provider }) => ({
      provider: {
        distributionMethod: {
          ...provider.distributionMethod,
          _id: String(provider.distributionMethod._id),
        },
      },
    }));

  const uniqSubjects = uniqBy(dataFiltered, 'provider.distributionMethod._id');

  return defaultOrderBy({
    data: uniqSubjects,
    order: { orderBy: 'provider.distributionMethod.name' },
  }).slice(0, 15);
}

export function formatFilterLanguage(formatFilterValue, term) {
  return formatFilterValue
    .filter(({ codeIso }) => {
      const sanitizedLanguageCodeIso = removeAccents(String(codeIso).trim().toLowerCase());
      const sanitizedTerm = removeAccents(term.trim().toLowerCase());

      try {
        return sanitizedLanguageCodeIso.indexOf(sanitizedTerm) !== -1;
      } catch (e) {
        captureException({ error: e, type: e.type || 'function', component: 'formatFilterLanguage' });
        return false;
      }
    })
    .map(language => ({ ...language, labelAutocomplete: language.codeIso.toLocaleUpperCase() }));
}

export function formatFilterCategory(formatFilterValue) {
  const dataFiltered = formatFilterValue.map(category => ({
    ...category,
    categoryName: formatUnknownText(sanitizeString(category.categoryName)),
  }));

  return defaultOrderBy({ data: dataFiltered, order: { orderBy: 'categoryName' } });
}

export function formatFilterStatus(formatFilterValue, term) {
  return formatFilterValue.filter(({ label }) => {
    const sanitizedLabel = removeAccents(String(label).trim().toLowerCase());
    const sanitizedTerm = removeAccents(term.trim().toLowerCase());

    try {
      return sanitizedLabel.indexOf(sanitizedTerm) !== -1;
    } catch (e) {
      captureException({ error: e, type: e.type || 'function', component: 'formatFilterStatus' });
      return false;
    }
  });
}

export function filterData({ filters, filtersConfig, data }) {
  return data.filter(entry =>
    Object.keys(filters)
      .map(key => {
        const filterConfig = filtersConfig.find(({ slug }) => slug === key);

        return filterConfig && filters[key].includes(get(entry, filterConfig.optionsMapping.dataKey));
      })
      .every(Boolean)
  );
}

export function defaultOrderBy({ data, order }) {
  return orderBy(
    data,
    [
      item => {
        const value = get(item, order.orderBy);
        const formattedValue = formatUnknownText(value);

        if (formattedValue === CONSTANTS.unknownText || formattedValue === CONSTANTS.noText) {
          return null;
        }

        return removeAccents(formattedValue.trim().toLowerCase());
      },
    ],
    [order.direction || 'asc']
  );
}

function getOrderIntFn(orderSlug) {
  return function orderIntFn(item) {
    const value = get(item, orderSlug);

    const isNumberStringify = typeof value === 'string' && value.length > 0 && !Number.isNaN(Number(value));
    const isNumber = typeof value === 'number' && !Number.isNaN(value);

    return isNumberStringify || isNumber ? Number(value) : null;
  };
}

export function orderByInt({ data, order }) {
  return orderBy(data, [getOrderIntFn(order.orderBy)], [order.direction || 'asc']);
}

export function orderData({ keys, data, order }) {
  if (!order?.orderBy) {
    return data;
  }

  const formatedOrder = {
    ...order,
    direction: order.direction.toLowerCase(),
  };

  if (!keys) {
    return defaultOrderBy({ data, order: formatedOrder });
  }

  const { orderByFunction } = keys.find(({ dataKey }) => dataKey === formatedOrder.orderBy) || {};

  return orderByFunction
    ? orderByFunction({ data, order: formatedOrder })
    : defaultOrderBy({ data, order: formatedOrder });
}

export function orderByCharterCategories({ data, order }) {
  return orderBy(
    data,
    [item => (get(item, order.orderBy)?.length ? get(item, order.orderBy)[0]._id : null)],
    [order.direction || 'asc']
  );
}

export function paginateData({ data, limit, offset = 0 }) {
  const items = limit ? data.slice(offset, Math.min(offset + limit, data.length)) : data;

  return {
    items,
    totalItem: data.length,
  };
}
