import { token } from 'timeone-components';
import posthog from 'posthog-js';

import { isPosthogActive, captureException } from '../../utils';
import { getMeUid, handleFetchResponse } from '../utils';
import {
  createUserApi,
  createUserPreferenceApi,
  deleteUserAdvertiserRolesApi,
  deleteUserPreferenceApi,
  getAssignableRolesApi,
  getLogout,
  getMeApi,
  getUserCampaignsRolesApi,
  getUserPreferenceApi,
  getUserResourcesApi,
  getUsersApi,
  putUserStatusApi,
  searchUserApi,
  updateUserAdvertiserRolesApi,
  updateUserPreferenceApi,
} from './user.api';

export const logOutUser = async () => {
  await getLogout();
  token.clear(process.env.REACT_APP_COOKIE_DOMAIN_URL);
  token.redirectToSSO(`${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/oauth`);
};

export const getUserResources = () => {
  const userId = getMeUid();

  return getUserResourcesApi(userId).then(({ data }) => {
    if (data && data.getUserResources) {
      return data.getMe;
    }

    throw new Error('Error while fetching user resources');
  });
};

export async function getMe() {
  const result = await getMeApi();

  if (result?.data?.getMe) {
    return result.data.getMe;
  }

  throw new Error('Error while fetching user data');
}

export async function getUsers(options) {
  const result = await getUsersApi(options);

  return {
    items: result['hydra:member'],
    totalItem: result['hydra:totalItems'],
  };
}

export async function createUserPreference(key, value) {
  const response = await createUserPreferenceApi(key, value);

  return handleFetchResponse(response);
}

export async function updateUserPreference(id, key, value) {
  const response = await updateUserPreferenceApi(id, key, value);

  return handleFetchResponse(response);
}

export async function getUserPreference(key) {
  const response = await getUserPreferenceApi(key);

  return handleFetchResponse(response);
}

export async function deleteUserPreference(id) {
  const response = await deleteUserPreferenceApi(id);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }
}

export async function searchUser({ email, resourceId }) {
  const { data, errors } = await searchUserApi({ email, resourceId });

  if (errors?.[0]?.extensions?.code) {
    throw new Error(errors[0].extensions.code);
  }

  if (data && 'searchUser' in data) {
    return data.searchUser;
  }

  throw new Error('Error while trying to find user');
}

export const getAssignableRoles = async (id, resourceType) => {
  const response = await getAssignableRolesApi(id, resourceType);

  return handleFetchResponse(response);
};

function formatRolesPayload(payload, isDeletion) {
  return {
    userRoles: payload
      .filter(Boolean)
      .map(({ advertiserId, roleId, campaignIds }) => {
        // For now we do not remove advertiser role
        const minimalPayload = isDeletion
          ? { roleId: parseInt(roleId, 10) }
          : {
              roleId: parseInt(roleId, 10),
              userHasAdvertisers: [
                {
                  serviceAdvertiserId: advertiserId.toString(),
                },
              ],
            };

        if (campaignIds) {
          return {
            ...minimalPayload,
            userHasCampaigns: campaignIds.map(serviceCampaignId => ({
              serviceAdvertiserId: advertiserId.toString(),
              serviceCampaignId: serviceCampaignId.toString(),
            })),
          };
        }

        return minimalPayload;
      })
      .filter(Boolean),
  };
}

function logRoleEvent(type, userId, data) {
  try {
    const initiatorId = getMeUid();

    data?.filter(Boolean)?.forEach(({ roleId, advertiserId, campaignIds }) => {
      campaignIds?.forEach(campaignId => {
        posthog.capture(`user:role:${type}`, {
          campaignId,
          roleId,
          advertiserId,
          userId,
          initiatorId,
        });
      });
    });
  } catch (e) {
    captureException({ error: e, type: e.type || 'function', component: 'logRoleEvent' });
  }
}

export async function updateUserRoles(id, body) {
  const response = await updateUserAdvertiserRolesApi(id, formatRolesPayload(body));

  // if (response.ok && isPosthogActive()) {
  //   logRoleEvent('update', id, body);
  // }

  return handleFetchResponse(response);
}

export async function deleteUserRoles(id, body) {
  const response = await deleteUserAdvertiserRolesApi(id, formatRolesPayload(body, true));

  if (response.ok && isPosthogActive()) {
    logRoleEvent('delete', id, body);
  }

  return handleFetchResponse(response);
}

export async function createUser(body) {
  const response = await createUserApi(body);

  return handleFetchResponse(response);
}

export async function getUserCampaignsRoles({ advertiserId, userId, campaigns }) {
  const meId = getMeUid();

  const response = await getUserCampaignsRolesApi({ userId: userId || meId, advertiserId });
  const userHasCampaigns = await handleFetchResponse(response);

  return userHasCampaigns
    .map(userHasCampaign => {
      const campaign = campaigns.find(({ id }) => id === userHasCampaign.serviceCampaignId);

      if (!campaign) {
        return null;
      }

      return { ...userHasCampaign, campaign };
    })
    .filter(Boolean)
    .reduce((acc, { userRole, campaign }) => {
      if (acc[userRole?.roleId]) {
        acc[userRole.roleId].push(campaign);
      } else {
        acc[userRole.roleId] = [campaign];
      }
      return acc;
    }, {});
}

export async function getUserCampaignsPermissions({ advertiserId, userId }) {
  const meId = getMeUid();

  const response = await getUserCampaignsRolesApi({ userId: userId || meId, advertiserId });

  return handleFetchResponse(response);
}

export async function updateUserStatus(userId, active) {
  const response = await putUserStatusApi(userId, { active });

  return handleFetchResponse(response);
}

export async function getCustomGraphs(campaignId, defaultComponents) {
  const getLocalstorageData = JSON.parse(localStorage.getItem(`home-dashboard-layout:${campaignId}`));

  const newElements = getLocalstorageData?.elements.map(element => {
    if (element.type === 'component') {
      return { ...element, ...defaultComponents[element.slug] };
    }

    return element;
  });

  return {
    layouts: getLocalstorageData?.layouts,
    elements: newElements,
  };
}

export async function setCustomGraphs(campaignId, graphs) {
  localStorage.setItem(`home-dashboard-layout:${campaignId}`, JSON.stringify(graphs));
}

export async function deleteCustomGraphs(campaignId) {
  localStorage.removeItem(`home-dashboard-layout:${campaignId}`);
}

export default {
  createUser,
  createUserPreference,
  deleteCustomGraphs,
  deleteUserPreference,
  deleteUserRoles,
  getAssignableRoles,
  getCustomGraphs,
  getMe,
  getUserCampaignsRoles,
  getUserPreference,
  getUserResources,
  getUsers,
  logOutUser,
  searchUser,
  setCustomGraphs,
  updateUserPreference,
  updateUserRoles,
  updateUserStatus,
  getUserCampaignsPermissions,
};
