import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

import { advertiserService, userService, roleService } from '../services';
import { isPosthogActive, isSentryActive, captureException } from '../utils';

const USER_PREFERENCE = {
  selectedAdvertiser: 'advertiser:dashboard:selectedAdvertiser',
  selectedCampaign: 'advertiser:dashboard:selectedCampaign',
};

export const AdvertisersContext = React.createContext({
  advertisers: [],
  selectedAdvertiser: {},
  setSelectedAdvertiserFromId: () => {},
  campaigns: [],
  loadCampaigns: () => {},
  selectedCampaign: {},
});

function AdvertisersProvider({ children }) {
  const [state, setState] = useState({
    advertisers: [],
    selectedAdvertiser: {},
    campaigns: [],
    selectedCampaign: {},
  });
  const [preferenceHash, setPreferenceHash] = useState({
    advertiser: null,
    campaign: null,
  });

  useEffect(() => {
    if (isSentryActive()) {
      if (state?.selectedAdvertiser?.id) {
        const { roles, permissions, ...advertiser } = state.selectedAdvertiser;

        Sentry.setContext('tog:advertiser', advertiser);
        Sentry.setContext('tog:acl', {
          roles: roles?.map(({ name }) => name),
          permissions,
        });
      }

      if (state?.selectedCampaign?.id) {
        Sentry.setContext('tog:campaign', {
          ...state.selectedCampaign,
        });
      }
    }
  }, [state]);

  async function getAdvertisers() {
    try {
      const advertisersList = await advertiserService.getAdvertisers({
        itemsPerPage: 200,
        pagination: true,
        status: true,
      });

      if (!advertisersList || advertisersList?.length === 0) {
        return null;
      }

      return advertisersList;
    } catch (e) {
      captureException({ error: e, type: e.type || 'fecthData', component: 'AdvertisersProvider' });

      return null;
    }
  }

  async function getIdFromPreference(key, type) {
    try {
      const preference = await userService.getUserPreference(key);
      const tmpPreferenceId = preference?.[0]?.value ? JSON.parse(preference[0].value) : null;

      if (tmpPreferenceId) {
        setPreferenceHash(prevState => ({ ...prevState, [type]: preference[0].id }));
      } else {
        setPreferenceHash(prevState => ({ ...prevState, [type]: null }));
      }

      return tmpPreferenceId?.id ? tmpPreferenceId.id : tmpPreferenceId; // Le temps de migrer la facon dont on stock le selected advertiser. On ne stock  plus que son id, on n'utilise pas les autre infos. 15/12/2020
    } catch (e) {
      captureException({ error: e, type: e.type || 'fecthData', component: 'AdvertisersProvider' });
      return null;
    }
  }

  async function getSelectedAdvertiser(advertisers) {
    if (!advertisers?.length > 0) {
      return null;
    }

    try {
      const advertiserId =
        (await getIdFromPreference(USER_PREFERENCE.selectedAdvertiser, 'advertiser')) ?? advertisers[0].id;
      /* eslint-disable sonarjs/prefer-immediate-return */
      const advertiser = await advertiserService.getAdvertiser(advertiserId);

      return advertiser;
    } catch (e) {
      return null;
    }
  }

  async function getCampaigns(selectedAdvertiser) {
    try {
      const campaigns = await advertiserService.getCampaigns(selectedAdvertiser.id);

      if (!campaigns || campaigns?.length === 0) {
        return null;
      }

      return campaigns;
    } catch (e) {
      captureException({ error: e, type: e.type || 'fecthData', component: 'AdvertisersProvider' });

      return null;
    }
  }

  async function getSelectedEntity({ entities, entityName, userPreferenceEntity, selectedAdvertiser }) {
    if (!entities?.length > 0 || !selectedAdvertiser?.id) {
      return null;
    }

    try {
      const entityId =
        (await getIdFromPreference(`${userPreferenceEntity}:${selectedAdvertiser.id}`, entityName)) ?? entities[0].id;

      return entities.find(({ id }) => id === entityId) || entities[0];
    } catch (e) {
      captureException({ error: e, type: e.type || 'fecthData', component: 'AdvertisersProvider' });
      return null;
    }
  }

  async function getCampaignsWithSelected(selectedAdvertiser) {
    if (!selectedAdvertiser?.id) {
      return {
        campaigns: null,
        selectedCampaign: null,
      };
    }

    const campaigns = await getCampaigns(selectedAdvertiser);

    if (!campaigns) {
      return {
        campaigns: null,
        selectedCampaign: null,
      };
    }
    const userHasCampaigns = await userService.getUserCampaignsPermissions({ advertiserId: selectedAdvertiser.id });
    const permissionsByRoles = await roleService.getPermissionsByRolesId();

    const campaignsWithPermissions = campaigns.map(campaign => {
      const role = userHasCampaigns.find(({ serviceCampaignId }) => Number(serviceCampaignId) === campaign.id);

      if (!permissionsByRoles[role?.userRole?.roleId]) {
        return campaign;
      }

      const permissions = permissionsByRoles[role.userRole.roleId];

      return { ...campaign, permissions };
    });

    const selectedCampaign = await getSelectedEntity({
      entities: campaignsWithPermissions,
      entityName: 'campaign',
      userPreferenceEntity: USER_PREFERENCE.selectedCampaign,
      selectedAdvertiser,
    });

    return {
      campaigns: campaignsWithPermissions,
      selectedCampaign,
    };
  }

  async function getInitialeData() {
    const advertisers = await getAdvertisers();
    const selectedAdvertiser = await getSelectedAdvertiser(advertisers);

    const entitesValues = await Promise.all([getCampaignsWithSelected(selectedAdvertiser)]);
    const flattenEntitesValues = Object.assign({}, ...entitesValues);

    if (isPosthogActive()) {
      posthog.register({
        selectedAdvertiserId: selectedAdvertiser?.id,
        selectedCampaignId: flattenEntitesValues?.selectedCampaign?.id,
      });
    }
    setState(prevState => ({
      ...prevState,
      advertisers,
      selectedAdvertiser,
      ...flattenEntitesValues,
    }));
  }

  useEffect(() => {
    getInitialeData();
  }, []);

  async function refreshAdvertiserProvider() {
    getInitialeData();
  }

  const setSelectedAdvertiserFromId = async advertiserId => {
    try {
      const selectedAdvertiser = await advertiserService.getAdvertiser(advertiserId);

      if (!selectedAdvertiser?.id) {
        console.error(`Fail to load advertiser #${advertiserId}`);

        return;
      }

      const entitesValues = await Promise.all([getCampaignsWithSelected(selectedAdvertiser)]);
      const flattenEntitesValues = Object.assign({}, ...entitesValues);

      if (preferenceHash.advertiser) {
        userService.updateUserPreference(
          preferenceHash.advertiser,
          USER_PREFERENCE.selectedAdvertiser,
          selectedAdvertiser.id
        );
      } else {
        const { id } = await userService.createUserPreference(
          USER_PREFERENCE.selectedAdvertiser,
          selectedAdvertiser.id
        );

        setPreferenceHash(prevState => ({ ...prevState, advertiser: id }));
      }

      setState(prevState => ({
        ...prevState,
        selectedAdvertiser,
        ...flattenEntitesValues,
      }));
    } catch (e) {
      console.error(`Cannot add the advertiser #${advertiserId}, not found`);
    }
  };

  const setSelectedEntityFromId = async ({
    entityId,
    entities,
    userPreferenceEntity,
    preferenceHashKey,
    stateEntityKey,
  }) => {
    const selectedEntity = entities.find(entity => parseInt(entity.id, 10) === parseInt(entityId, 10));

    if (selectedEntity && preferenceHash[preferenceHashKey]) {
      userService.updateUserPreference(
        preferenceHash[preferenceHashKey],
        `${userPreferenceEntity}:${state.selectedAdvertiser.id}`,
        selectedEntity.id
      );
      setState(prevState => ({ ...prevState, [stateEntityKey]: selectedEntity }));
    } else if (selectedEntity) {
      const { id } = await userService.createUserPreference(
        `${userPreferenceEntity}:${state.selectedAdvertiser.id}`,
        selectedEntity.id
      );

      setPreferenceHash(prevState => ({ ...prevState, [preferenceHashKey]: id }));
      setState(prevState => ({ ...prevState, [stateEntityKey]: selectedEntity }));
    }
  };

  const setSelectedCampaignFromId = campaignId =>
    setSelectedEntityFromId({
      entityId: campaignId,
      entities: state.campaigns,
      userPreferenceEntity: USER_PREFERENCE.selectedCampaign,
      preferenceHashKey: 'campaign',
      stateEntityKey: 'selectedCampaign',
    });

  const contextValue = useMemo(
    () => ({
      ...state,
      setSelectedCampaignFromId,
      setSelectedAdvertiserFromId,
      refreshAdvertiserProvider,
    }),
    [state]
  );

  return <AdvertisersContext.Provider value={contextValue}>{children}</AdvertisersContext.Provider>;
}

AdvertisersProvider.propTypes = {
  children: PropTypes.PropTypes.node.isRequired,
};

export default AdvertisersProvider;
